import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useMediaQuery,
} from '@chakra-ui/react'
import Modal from 'components/presentational/Modal'

const ModalDrawer = ({
  isOpen,
  onClose,
  placement,
  closeOnOverlayClick,
  children,
}) => {
  const [isDesktop] = useMediaQuery(['(min-width: 768px)'])

  return isDesktop ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      {children}
    </Modal>
  ) : (
    <Drawer
      isOpen={isOpen}
      placement={placement}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <DrawerOverlay backgroundColor="#00000099" />
      <DrawerContent borderTopLeftRadius="drawer" borderTopRightRadius="drawer">
        <DrawerCloseButton data-cy="drawer-close-icon" />
        {children}
      </DrawerContent>
    </Drawer>
  )
}

export default ModalDrawer
