import { ChakraProvider } from '@chakra-ui/react'
import {
  LocationContextProvider,
  NetworkContextProvider,
} from 'components/contextProvider'
import Router from 'navigation/Router'
import { BrowserRouter } from 'react-router-dom'
import initRum from 'services/monitoring'
import theme from 'theme'

// initialise CloudWatch RUM web client
initRum()

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <NetworkContextProvider>
          <LocationContextProvider>
            <Router />
          </LocationContextProvider>
        </NetworkContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  )
}

export default App
