const Heading = {
  variants: {
    'headline.xl.medium': {
      fontSize: '2rem',
      fontWeight: '500',
      lineHeight: '2.375rem',
      letterSpacing: '-0.025em',
    },
    'headline.lg.medium': {
      fontSize: '1.75rem',
      fontWeight: '500',
      lineHeight: '2.125rem',
      letterSpacing: '-0.025em',
    },
    'headline.md.medium': {
      fontSize: '1.5rem',
      fontWeight: '500',
      lineHeight: '1.875rem',
      letterSpacing: '-0.025em',
    },
    'headline.md.bold': {
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '1.875rem',
      letterSpacing: '-0.025em',
    },
    'headline.sm.medium': {
      fontSize: '1.25rem',
      fontWeight: '500',
      lineHeight: '1.625rem',
      letterSpacing: '-0.025em',
    },
    'headline.xs.medium': {
      fontSize: '1.125rem',
      fontWeight: '500',
      lineHeight: '1.5rem',
      letterSpacing: '-0.025em',
    },
    'headline.xs.regular': {
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      letterSpacing: '-0.025em',
    },
  },
}

export default Heading
