import moment from 'moment'

export const isAfterAppointmentMinStartTime = (currentTime, startTime) => {
  const appointmentTime = moment(startTime)
  const maxStartTime = appointmentTime.add(15, 'minutes')

  return currentTime.isAfter(maxStartTime)
}

export const isBeforeAppointmentMaxStartTime = (currentTime, startTime) => {
  const appointmentTime = moment(startTime)
  const maxStartTime = appointmentTime.subtract(2, 'minutes')

  return currentTime.isBefore(maxStartTime)
}
