import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from '@chakra-ui/react'
import { Icons } from 'components'
import { UserContext } from 'components/contextProvider'
import { Footer } from 'components/presentational/'
import { STATUS_COMPLETED_SUCCESSFULLY } from 'constants'
import moment from 'moment'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'utils/hooks.ts'
import { getPartnerSupportEmail } from 'utils/utils'
import { calculateAppointmentTime } from './utils'

const PostVideoCall = () => {
  const navigate = useNavigate()
  const [redirect] = useSessionStorage('redirect')
  const [partnerId] = useSessionStorage('partnerId')
  const { user } = useContext(UserContext)
  const isDoctor = user !== null

  const onClose = () => {
    if (isDoctor) navigate('/')
    else if (redirect) window.location.href = decodeURIComponent(redirect)
    else navigate('/lobby', { replace: true })
  }

  if (isDoctor) {
    const location = useLocation()
    const { status, patientJoinedAt, completedAt } = location.state
    const duration = moment
      .duration(moment(completedAt).diff(moment(patientJoinedAt)))
      .asSeconds()

    return (
      <Flex direction="column">
        <Flex
          px="5"
          flex="1"
          direction="column"
          justify="center"
          align="center"
          minH="100dvh"
        >
          <Flex
            px="5"
            align="center"
            justify="center"
            direction="column"
            flex={{ base: '1', md: 'unset' }}
          >
            <Icon as={Icons.Checkmark} boxSize={110} mb="8" />
            <Heading variant="headline.md.medium">
              Das Videogespräch wurde nun beendet.
            </Heading>

            {status === STATUS_COMPLETED_SUCCESSFULLY && duration > 0 ? (
              <Text mt="1">
                Dauer der Videosprechstunde:{' '}
                {calculateAppointmentTime(duration)}
              </Text>
            ) : null}
          </Flex>

          <Flex direction="column" align="center" py="8" mt="8">
            <Button onClick={onClose}>Zurück zur Startseite</Button>
            <Text variant="body.sm.regular" mt="6">
              Technische Probleme?{' '}
              <Link
                color="primary.500"
                href="mailto:partnersuccess@teleclinic.com"
              >
                Melden Sie sich bei uns!
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex direction="column">
      <Flex
        paddingX="5"
        flex="1"
        direction="column"
        justify="center"
        align="center"
        minH="100dvh"
      >
        <Flex
          px="5"
          align="center"
          justify="center"
          direction="column"
          flex={{ base: '1', md: 'unset' }}
        >
          <Image w="135px" src="/img/PostCall.png" alt="TeleClinic" mb="8" />
          <Heading variant="headline.md.medium">
            Vielen Dank für Ihren Besuch.
          </Heading>
          <Text mt="1">Das Videogespräch wurde nun beendet.</Text>
        </Flex>

        <Flex direction="column" align="center" py="8" mt="8">
          <Button onClick={onClose}>Zurück zur Startseite</Button>
          <Text variant="body.sm.regular" mt="6">
            Technische Probleme?{' '}
            <Link
              color="primary.500"
              href={`mailto:${getPartnerSupportEmail(partnerId)}`}
            >
              Melden Sie sich bei uns!
            </Link>
          </Text>
        </Flex>
      </Flex>
      <Box bg="surface.secondary">
        <Footer target="_blank" />
      </Box>
    </Flex>
  )
}

export default PostVideoCall
