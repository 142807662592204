// api
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN
export const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID

export const REQUEST_HEADER_FORM = 'multipart/form-data'
export const REQUEST_HEADER_FORM_URL_ENCODED =
  'application/x-www-form-urlencoded'
export const REQUEST_HEADER_JSON = 'application/json'

// zendesk chat url and key
export const ZENDESK_CHAT_KEY = process.env.REACT_APP_ZENDESK_CHAT_KEY

// Gender
export const GENDER_UNKNOWN = 1
export const GENDER_FEMALE = 3
export const GENDER_MALE = 2
export const GENDER_DIVERSE = 4

// appointment status codes
export const STATUS_INITIALIZED = 'initialized'
export const STATUS_IN_PROGRESS = 'in_progress'
export const STATUS_COMPLETED = 2
export const STATUS_FAILED = 3
export const STATUS_CANCELED = 4
export const STATUS_COMPLETED_SUCCESSFULLY = 'completed_successfully'
export const STATUS_COMPLETED_UNSUCCESSFULLY = 'completed_unsuccessfully'

// media devices
export const DEVICE_TYPE_MICROPHONE = 'audioinput'
export const DEVICE_TYPE_CAMERA = 'videoinput'
export const DEVICE_TYPE_TOGGLE_CAMERA = 'toggleVideo'
