import { createContext, useMemo } from 'react'
import { queryParams } from 'utils/utils'
import { useLocation } from 'react-router-dom'

export const LocationContext = createContext()

function LocationContextProvider({ children }) {
  const { pathname, search } = useLocation()
  const filter =
    queryParams('filter') !== null ? queryParams('filter') : 'upcoming'
  const page = queryParams('page') !== null ? queryParams('page') : '1'
  const isIframe =
    queryParams('isIframeNavigation') !== null &&
    queryParams('isIframeNavigation') === 'true'

  const context = useMemo(() => {
    return {
      path: pathname,
      page,
      filter,
      searchQuery: search,
      isIframe,
    }
  }, [pathname, page, filter, search, isIframe])

  return (
    <LocationContext.Provider value={context}>
      {children}
    </LocationContext.Provider>
  )
}

export default LocationContextProvider
