import { extendTheme } from '@chakra-ui/react'
import * as components from './components'

export default extendTheme({
  colors: {
    extracolor: {
      'light-raspberry': '#F9E6E7',
      raspberry: '#CA3E61',
      'light-blue': '#E0EBF8',
      blue: '#297BDB',
      'light-gold': '#FEF1C3',
      gold: '#6C560C',
      'light-turquoise': '#E4E8E9',
      turquoise: '#2C7C83',
      'light-green': '#E5EFDC',
      green: '#5B8C23',
      'light-brown': '#F0EBDF',
      brown: '#7D6C40',
      'light-clay': '#ECEBEA',
      clay: '#5B534B',
      'light-purple': '#EBE5F8',
      purple: '#706093',
    },
    highlight: {
      100: '#FCF0CC',
    },
    neutral: {
      0: '#FFFFFF',
      50: '#F1F2F4',
      100: '#E7E8E9',
      200: '#D3D5D6',
      250: '#D9D9D9',
      300: '#B9BCBE',
      400: '#A5A6AA',
      450: '#333333',
      500: '#86868A',
      550: '#666666',
      600: '#696A6F',
      700: '#3F4146',
      800: '#313338',
      900: '#25272D',
      1000: '#18181F',
    },
    primary: {
      50: '#F2F7F8',
      100: '#E5F0F1',
      200: '#B8E0E3',
      300: '#7FCAD4',
      400: '#42B6C3',
      // 500: '#153F45',
      500: '#008C99',
      600: '#086E7E',
      700: '#0E5464',
      800: '#113F4E',
      900: '#122E39',
    },
    semantics: {
      'information-lighter': '#E9EFFA',
      information: '#3F74DF',
      'information-darker': '#0D2556',
      'success-lighter': '#E8F1E8',
      success: '#2B9135',
      'success-darker': '#003402',
      'warning-lighter': '#F6EDE3',
      warning: '#BC5A00',
      'warning-darker': '#3F2100',
      'error-lighter': '#F9EBE8',
      error: '#D73616',
      'error-darker': '#4E0E04',
    },
  },
  sizes: {
    'page-content-width': '704px',
  },
  fonts: {
    body: 'CentraNo2, Helvetica',
    heading: 'CentraNo2, Helvetica',
  },
  fontSizes: {
    '2xs': '0.625rem',
  },
  semanticTokens: {
    colors: {
      border: {
        primary: {
          default: 'neutral.600',
          _dark: 'neutral.200',
        },
        secondary: {
          default: 'neutral.400',
          _dark: 'neutral.600',
        },
        active: {
          default: 'primary.500',
          _dark: 'primary.300',
        },
        hover: {
          default: 'neutral.400',
          _dark: 'neutral.600',
        },
        disabled: {
          default: 'neutral.200',
          _dark: 'neutral.700',
        },
        'highlight-brand': {
          default: 'primary.500',
          _dark: 'primary.300',
        },
        button: {
          'secondary-default': {
            default: 'primary.500',
            _dark: 'primary.300',
          },
          'secondary-hover': {
            default: 'primary.600',
            _dark: 'primary.400',
          },
          'secondary-pressed': {
            default: 'primary.800',
            _dark: 'primary.600',
          },
          'secondary-tertiary-disabled': {
            default: 'neutral.300',
            _dark: 'neutral.300',
          },
          'tertiary-default': {
            default: 'neutral.200',
            _dark: 'neutral.700',
          },
          'tertiary-hover': {
            default: 'neutral.300',
            _dark: 'neutral.500',
          },
          'tertiary-pressed': {
            default: 'neutral.400',
            _dark: 'neutral.600',
          },
          'partner-default': {
            default: 'neutral.900',
            _dark: 'neutral.0',
          },
          'partner-hover': {
            default: 'neutral.700',
            _dark: 'neutral.300',
          },
          'partner-pressed': {
            default: 'neutral.600',
            _dark: 'neutral.600',
          },
        },
        semantic: {
          information: {
            default: 'semantics.information',
            _dark: 'semantics.information',
          },
          success: {
            default: 'semantics.success',
            _dark: 'semantics.success',
          },
          warning: {
            default: 'semantics.warning',
            _dark: 'semantics.warning',
          },
          error: {
            default: 'semantics.error',
            _dark: 'semantics.error',
          },
        },
      },
      content: {
        primary: {
          default: 'neutral.1000',
          _dark: 'neutral.0',
        },
        secondary: {
          default: 'neutral.600',
          _dark: 'neutral.400',
        },
        tertiary: {
          default: 'neutral.400',
          _dark: 'neutral.600',
        },
        active: {
          default: 'primary.500',
          _dark: 'primary.300',
        },
        disabled: {
          default: 'neutral.300',
          _dark: 'neutral.600',
        },
        reversed: {
          default: 'neutral.0',
          _dark: 'neutral.1000',
        },
        'highlight-brand': {
          default: 'primary.500',
          _dark: 'primary.400',
        },
        button: {
          primary: {
            default: 'neutral.0',
            _dark: 'neutral.1000',
          },
          'secondary-default': {
            default: 'primary.500',
            _dark: 'primary.300',
          },
          'secondary-hover': {
            default: 'primary.600',
            _dark: 'primary.400',
          },
          'secondary-pressed': {
            default: 'primary.800',
            _dark: 'primary.600',
          },
          'tertiary-default': {
            default: 'neutral.1000',
            _dark: 'neutral.0',
          },
          'tertiary-hover': {
            default: 'neutral.600',
            _dark: 'neutral.600',
          },
          'tertiary-pressed': {
            default: 'neutral.400',
            _dark: 'neutral.400',
          },
          partner: {
            default: 'neutral.0',
            _dark: 'neutral.1000',
          },
        },
        label: {
          red: {
            default: 'extracolor.raspberry',
            _dark: 'extracolor.raspberry',
          },
          blue: {
            default: 'extracolor.blue',
            _dark: 'extracolor.blue',
          },
          yellow: {
            default: 'extracolor.gold',
            _dark: 'extracolor.gold',
          },
          turquoise: {
            default: 'extracolor.turquoise',
            _dark: 'extracolor.turquoise',
          },
          green: {
            default: 'extracolor.green',
            _dark: 'extracolor.green',
          },
          brown: {
            default: 'extracolor.brown',
            _dark: 'extracolor.brown',
          },
          clay: {
            default: 'extracolor.clay',
            _dark: 'extracolor.clay',
          },
          purple: {
            default: 'extracolor.purple',
            _dark: 'extracolor.purple',
          },
        },
        link: {
          primary: {
            default: 'primary.500',
            _dark: 'primary.300',
          },
          secondary: {
            default: 'neutral.1000',
            _dark: 'neutral.0',
          },
        },
        semantic: {
          information: {
            default: 'semantics.information',
            _dark: 'semantics.information',
          },
          success: {
            default: 'semantics.success',
            _dark: 'semantics.success',
          },
          warning: {
            default: 'semantics.warning',
            _dark: 'semantics.warning',
          },
          error: {
            default: 'semantics.error',
            _dark: 'semantics.error',
          },
        },
      },
      surface: {
        primary: {
          default: 'neutral.0',
          _dark: 'neutral.1000',
        },
        secondary: {
          default: 'neutral.50',
          _dark: 'neutral.900',
        },
        tertiary: {
          default: 'neutral.100',
          _dark: 'neutral.800',
        },
        hover: {
          default: 'neutral.200',
          _dark: 'neutral.700',
        },
        active: {
          default: 'primary.50',
          _dark: 'primary.900',
        },
        disabled: {
          default: 'neutral.50',
          _dark: 'neutral.900',
        },
        reversed: {
          default: 'neutral.1000',
          _dark: 'neutral.0',
        },
        'highlight-brand': {
          default: 'primary.500',
          _dark: 'primary.500',
        },
        highlight: {
          default: 'highlight.100',
          _dark: 'highlight.100',
        },
        button: {
          'primary-default': {
            default: 'primary.500',
            _dark: 'primary.500',
          },
          'primary-hover': {
            default: 'primary.600',
            _dark: 'primary.400',
          },
          'primary-pressed': {
            default: 'primary.700',
            _dark: 'primary.300',
          },
          'secondary-tertiary': {
            default: 'neutral.0',
            _dark: 'neutral.1000',
          },
          'partner-default': {
            default: 'neutral.900',
            _dark: 'neutral.900',
          },
          'partner-hover': {
            default: 'neutral.700',
            _dark: 'neutral.700',
          },
          'partner-pressed': {
            default: 'neutral.600',
            _dark: 'neutral.600',
          },
        },
        label: {
          red: {
            default: 'extracolor.light-raspberry',
            _dark: 'extracolor.light-raspberry',
          },
          blue: {
            default: 'extracolor.light-blue',
            _dark: 'extracolor.light-blue',
          },
          yellow: {
            default: 'extracolor.light-gold',
            _dark: 'extracolor.light-gold',
          },
          turquoise: {
            default: 'extracolor.light-turquoise',
            _dark: 'extracolor.light-turquoise',
          },
          green: {
            default: 'extracolor.light-green',
            _dark: 'extracolor.light-green',
          },
          brown: {
            default: 'extracolor.light-brown',
            _dark: 'extracolor.light-brown',
          },
          clay: {
            default: 'extracolor.light-clay',
            _dark: 'extracolor.light-clay',
          },
          purple: {
            default: 'extracolor.light-purple',
            _dark: 'extracolor.light-purple',
          },
        },
        semantic: {
          information: {
            default: 'semantics.information-lighter',
            _dark: 'semantics.information-darker',
          },
          success: {
            default: 'semantics.success-lighter',
            _dark: 'semantics.success-darker',
          },
          error: {
            default: 'semantics.error-lighter',
            _dark: 'semantics.error-darker',
          },
          warning: {
            default: 'semantics.warning-lighter',
            _dark: 'semantics.warning-darker',
          },
        },
      },
      line: {
        separator: {
          default: 'neutral.100',
          _dark: 'neutral.700',
        },
      },
      radio: {
        default: {
          default: 'neutral.500',
          _dark: 'neutral.500',
        },
        active: {
          default: 'primary.500',
          _dark: 'primary.400',
        },
      },
      toggle: {
        default: {
          default: 'neutral.100',
          _dark: 'neutral.600',
        },
        active: {
          default: 'primary.500',
          _dark: 'primary.400',
        },
        dot: {
          default: 'neutral.0',
          _dark: 'neutral.0',
        },
      },
      modal: {
        overlay: {
          default: '#00000099',
          _dark: '#2E2E2E99',
        },
      },
    },
    radii: {
      global: {
        small: '4px',
        medium: '8px',
        large: '16px',
        xl: '32px',
        '2xl': '128px',
      },
      button: '9999px',
      container: '1em',
    },
    fontWeights: {
      button: '500',
    },
  },
  components: { ...components },
  config: { initialColorMode: 'light', useSystemColorMode: false },
})
