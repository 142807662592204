const Text = {
  variants: {
    'body.md.regular': {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.375rem',
      letterSpacing: '-0.008em',
      weight: 'normal',
    },
    'body.md.regular-underlined': {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.375rem',
      letterSpacing: '-0.008em',
      textDecoration: 'underline',
    },
    'body.md.medium': {
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
      letterSpacing: '-0.025em',
    },
    'body.md.medium-underlined': {
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
      letterSpacing: '-0.025em',
      textDecoration: 'underline',
    },
    'body.md.bold': {
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '1.375rem',
      letterSpacing: '-0.025em',
    },
    'body.sm.regular': {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.375rem',
      letterSpacing: '-0.016em',
    },
    'body.sm.regular-underlined': {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.375rem',
      letterSpacing: '-0.016em',
      textDecoration: 'underline',
    },
    'body.sm.medium': {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
      letterSpacing: '-0.016em',
    },
    'body.sm.medium-underlined': {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
      letterSpacing: '-0.016em',
      textDecoration: 'underline',
    },
    'body.sm.bold': {
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.375rem',
      letterSpacing: '-0.016em',
    },
    'body.xs.regular': {
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1.125rem',
      letterSpacing: '-0.01em',
    },
    'body.xs.regular-underlined': {
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1.125rem',
      letterSpacing: '-0.01em',
      textDecoration: 'underline',
    },
    'body.xs.medium': {
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '1.125rem',
      letterSpacing: '-0.01em',
    },
    'body.xs.medium-underlined': {
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '1.125rem',
      letterSpacing: '-0.01em',
      textDecoration: 'underline',
    },
    'body.xs.bold': {
      fontSize: '0.75rem',
      fontWeight: '700',
      lineHeight: '1.125rem',
      letterSpacing: '-0.01em',
    },
    'body.xxs.regular': {
      fontSize: '0.625rem',
      fontWeight: '400',
      lineHeight: '1rem',
    },
    'body.xxs.regular-underlined': {
      fontSize: '0.625rem',
      fontWeight: '400',
      lineHeight: '1rem',
      textDecoration: 'underline',
    },
    'body.xxs.medium': {
      fontSize: '0.625rem',
      fontWeight: '500',
      lineHeight: '1rem',
    },
    'body.xxs.medium-underlined': {
      fontSize: '0.625rem',
      fontWeight: '500',
      lineHeight: '1rem',
      textDecoration: 'underline',
    },
    'body.xxs.bold': {
      fontSize: '0.625rem',
      fontWeight: '700',
      lineHeight: '1rem',
    },
  },
}

export default Text
