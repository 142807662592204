import { LoadingActivity } from 'components'
import { NetworkContext } from 'components/contextProvider'
import { Error, Footer, Header, Modal } from 'components/presentational'
import { useContext, useState } from 'react'

import styles from './Page.module.scss'

const Page = ({ children }) => {
  const [isLoading, setLoading] = useState(false)
  const { revokeAuthentication, isAuthenticated } = useContext(NetworkContext)
  const [error, setError] = useState(false)

  return (
    <div className={styles.container}>
      <Header
        revokeAuthentication={revokeAuthentication}
        isAuthenticated={isAuthenticated}
      />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {children.length > 1 ? (
            <>
              {error && (
                <Modal isOpen onClose={() => setError(false)}>
                  <Error title={error.title} description={error.description} />
                </Modal>
              )}
              <div className={styles.column}>
                {children[0]({ setLoading, setError })}
              </div>
              {isLoading ? (
                <LoadingActivity />
              ) : (
                <div className={styles.column}>
                  {children[1]({ setLoading, setError })}
                </div>
              )}
            </>
          ) : (
            children({ setLoading, setError })
          )}
        </div>
        <Footer backgroundColor="neutral.50" />
      </div>
    </div>
  )
}

export default Page
