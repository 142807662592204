import {
  HStack,
  Icon,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ReactComponent as CameraFlip } from 'assets/icons/CameraFlip.svg'
import { ReactComponent as CameraOff } from 'assets/icons/CameraOff.svg'
import { ReactComponent as CameraOn } from 'assets/icons/CameraOn.svg'
import { ReactComponent as MicOff } from 'assets/icons/MicOff.svg'
import { ReactComponent as MicOn } from 'assets/icons/MicOn.svg'
import { ReactComponent as Settings } from 'assets/icons/Settings.svg'
import { Icons } from 'components'

export function RoomControls(props) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <HStack
      bg="blackAlpha.600"
      borderRadius="2xl"
      borderBottomRadius={isMobile ? 'none' : '2xl'}
      backdropFilter="auto"
      backdropBlur="md"
      justify="center"
      transition="all 0.3s ease"
      w={isMobile ? '100%' : 'auto'}
      px="6"
      py="4"
      mb={isMobile ? '0' : '4'}
      spacing={isMobile ? '4' : '3'}
      {...props}
    />
  )
}

export function CameraToggle({ onClick, isActive }) {
  const bgProps = isActive
    ? {
        bg: 'white',
        _hover: { bg: 'whiteAlpha.800' },
        _active: { bg: 'whiteAlpha.700' },
      }
    : {
        bg: 'whiteAlpha.300',
        _hover: { bg: 'whiteAlpha.400' },
        _active: { bg: 'whiteAlpha.500' },
      }

  const iconProps = isActive
    ? { fill: 'neutral.800', as: CameraOn }
    : { fill: 'white', as: CameraOff }

  return (
    <IconButton
      p="3"
      minW="unset"
      {...bgProps}
      onClick={onClick}
      icon={<Icon w="5" h="5" {...iconProps} />}
    />
  )
}

export function MicrophoneToggle({ onClick, isActive }) {
  const bgProps = isActive
    ? {
        bg: 'white',
        _hover: { bg: 'whiteAlpha.800' },
        _active: { bg: 'whiteAlpha.700' },
      }
    : {
        bg: 'whiteAlpha.300',
        _hover: { bg: 'whiteAlpha.400' },
        _active: { bg: 'whiteAlpha.500' },
      }

  const iconProps = isActive
    ? { fill: 'neutral.800', as: MicOn }
    : { fill: 'white', as: MicOff }

  return (
    <IconButton
      p="3"
      minW="unset"
      {...bgProps}
      onClick={onClick}
      icon={<Icon w="5" h="5" {...iconProps} />}
    />
  )
}

export function CameraFlipToggle({ onClick }) {
  return (
    <IconButton
      p="3"
      minW="unset"
      bg="whiteAlpha.300"
      _hover={{ bg: 'whiteAlpha.400' }}
      _active={{ bg: 'whiteAlpha.500' }}
      icon={<Icon fill="white" w="5" h="5" as={CameraFlip} />}
      onClick={onClick}
    />
  )
}
export function SettingsButton({ onClick }) {
  return (
    <IconButton
      p="3"
      minW="unset"
      bg="whiteAlpha.300"
      _hover={{ bg: 'whiteAlpha.400' }}
      _active={{ bg: 'whiteAlpha.500' }}
      icon={<Icon fill="white" w="5" h="5" as={Settings} />}
      onClick={onClick}
    />
  )
}

export function HangupButton({ onClick }) {
  return (
    <IconButton
      p="4"
      minW="unset"
      colorScheme="red"
      icon={<Icon fill="white" w="3" h="3" as={Icons.Close} />}
      onClick={onClick}
    />
  )
}

export function ParticipantsListButton({ onClick }) {
  return (
    <Tooltip label="Teilnehmerliste" hasArrow>
      <IconButton
        p="3"
        minW="unset"
        bg="whiteAlpha.300"
        _hover={{ bg: 'whiteAlpha.400' }}
        _active={{ bg: 'whiteAlpha.500' }}
        icon={<Icon fill="neutral.0" w="5" h="5" as={Icons.Users} />}
        onClick={onClick}
      />
    </Tooltip>
  )
}
