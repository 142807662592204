import { Icon, keyframes } from '@chakra-ui/react'
import Icons from '../Icons'
import styles from './LoadingActivity.module.scss'

const rotating = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
`

const LoadingActivity = () => (
  <div className={styles.container}>
    <Icon
      as={Icons.Loading}
      fill="primary.500"
      w="50px"
      h="50px"
      animation={`${rotating} 2s linear infinite`}
    />
  </div>
)

export default LoadingActivity
