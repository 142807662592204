const FormLabel = {
  baseStyle: {
    color: 'content.primary',
    _invalid: {
      color: 'system.error',
    },
    _disabled: {
      opacity: 1,
    },
  },
}

export default FormLabel
