import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'

const Modal = ({
  isOpen,
  onClose,
  size = 'md',
  closeOnOverlayClick = true,
  children,
}) => (
  <ChakraModal
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    size={size}
    closeOnOverlayClick={closeOnOverlayClick}
  >
    <ModalOverlay bg="rgba(36, 36, 36, 0.7)" />
    <ModalContent m="5">
      <ModalCloseButton data-cy="modal-close-icon" />
      <ModalBody py="6">{children}</ModalBody>
    </ModalContent>
  </ChakraModal>
)

export default Modal
