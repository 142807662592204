import * as Yup from 'yup'

export default Yup.object().shape({
  username: Yup.string()
    .email('Bitte gib eine gültige E-Mail Adresse ein.')
    .required('E-Mail ist ein Pflichtfeld.'),
  password: Yup.string()
    .min(8, 'Dein Passwort muss mindestens 8 Zeichen lang sein.')
    .required('Bitte gib ein Passwort ein.'),
})
