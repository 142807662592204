import ReactPaginate from 'react-paginate'
import Icons from '../Icons'

import styles from './Pagination.module.scss'

function Pagination({ pageCount, onPageChange, forcePage }) {
  return (
    <ReactPaginate
      data-cy="pagination"
      previousLabel={<Icons.ChevronLeft />}
      nextLabel={<Icons.ChevronRight />}
      pageCount={pageCount}
      onPageChange={onPageChange}
      forcePage={forcePage}
      pageRangeDisplayed={4}
      marginPagesDisplayed={1}
      breakLinkClassName={styles.paginationLink}
      containerClassName={styles.pagination}
      previousLinkClassName={styles.paginationLinkPrevious}
      nextLinkClassName={styles.paginationLinkNext}
      disabledClassName={styles.paginationLinkDisabled}
      activeLinkClassName={styles.paginationLinkActive}
      pageClassName={styles.paginationLink}
      pageLinkClassName={styles.paginationLink}
    />
  )
}

export default Pagination
