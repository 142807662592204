/* eslint-disable consistent-return */
import { createContext, useState, useMemo } from 'react'
import actions from 'services/api/actions'
import { request } from '../../modules'

export const TOKEN_REFRESH_MARGIN = 100
export const NetworkContext = createContext()
const { localStorage } = window

const getAccessToken = () => localStorage.getItem('accessToken')
const getRefreshToken = () => localStorage.getItem('refreshToken')
const getTokenTimeStamp = () => localStorage.getItem('accessTokenTimeStamp')
const getTokenExpirationTime = () => localStorage.getItem('expirationTime')

function NetworkContextProvider({ children }) {
  const [isAuthenticated, setAuthenticated] = useState(getAccessToken())

  /* eslint-disable no-unused-vars */
  const removeAuth = () => {
    setAuthenticated(false)
  }

  const setAuth = (payload) => {
    // persist auth
    localStorage.setItem('accessToken', payload.access_token)
    localStorage.setItem('refreshToken', payload.refresh_token)
    localStorage.setItem('accessTokenTimeStamp', Date.now())
    localStorage.setItem('expirationTime', payload.expires_in)
    if (!isAuthenticated) {
      setAuthenticated(true)
    }
  }

  const revokeAuthentication = ({ sessionExpired }) => {
    setAuthenticated(null)
    request(actions.revokeToken({ accessToken: getAccessToken() }))
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessTokenTimeStamp')
    localStorage.removeItem('expirationTime')

    if (sessionExpired === true) {
      window.location.href = '?session-expired=true'
    }
  }

  let refreshLock = false

  function sleep(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  async function apiAction(action, params) {
    while (refreshLock) {
      await sleep(200) // eslint-disable-line no-await-in-loop
    }

    const tokenHasExpired =
      getTokenTimeStamp() &&
      (Date.now() - getTokenTimeStamp()) / 1000 >
        getTokenExpirationTime() - TOKEN_REFRESH_MARGIN

    if (tokenHasExpired && getRefreshToken()) {
      refreshLock = true
      // eslint-disable-next-line no-await-in-loop
      const response = await request(
        actions.renewToken({
          refreshToken: getRefreshToken(),
        }),
      )
      if (response.error) {
        revokeAuthentication({ sessionExpired: true })
        return
      }
      setAuth(response)
      refreshLock = false
    }
    const response = await request(
      action({ accessToken: getAccessToken(), ...params }),
    )
    if (response.error) {
      if (response.payload.status === 401) {
        revokeAuthentication({ sessionExpired: false })
      }
      return Promise.reject(response.payload)
    }
    return response
  }

  const context = useMemo(() => {
    return {
      ...Object.keys(actions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: (params) => apiAction(actions[key], params),
        }),
        {},
      ),
      setAuth,
      removeAuth,
      isAuthenticated: Boolean(isAuthenticated),
      revokeAuthentication,
    }
  }, [actions, setAuth, removeAuth, isAuthenticated, revokeAuthentication])

  return (
    <NetworkContext.Provider value={context}>
      {children}
    </NetworkContext.Provider>
  )
}

export default NetworkContextProvider
