import { Link } from 'react-router-dom'

import './LinkInlineIcon.scss'

const LinkInlineIcon = ({ to, children, onClick, ...props }) => {
  return (
    <Link to={to} onClick={onClick} className="LinkInlineIcon" {...props}>
      {children}
    </Link>
  )
}

export default LinkInlineIcon
