const overrides = {
  height: '9.3em',
  p: '1em 1em',
}

const Textarea = {
  sizes: {
    xs: overrides,
    sm: overrides,
    md: overrides,
    lg: overrides,
  },
  defaultProps: {
    focusBorderColor: 'primary.500',
    errorBorderColor: 'system.error',
  },
  variants: {
    outline: {
      borderColor: 'neutral.100',
      _placeholder: {
        color: 'neutral.300',
      },
    },
  },
}

export default Textarea
