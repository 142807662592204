const Select = {
  variants: {
    outline: {
      field: {
        height: 'unset',
        p: '0.75em 2.25em 0.75em 1em',
        borderColor: 'border.secondary',
        _placeholder: {
          color: 'content.secondary',
        },
        _hover: {
          borderColor: 'border.secondary',
        },
        _disabled: {
          bg: 'surface.disabled',
          color: 'content.disabled',
          borderColor: 'border.secondary',
          opacity: 1,
        },
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary.500',
  },
}

export default Select
