import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react'
import { Icons } from 'components'
import { Spacer } from 'components/presentational/'
import moment from 'moment'
import 'moment/locale/de'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Confirmation = ({ appointmentToken, appointmentDate }) => {
  const navigate = useNavigate()
  const [isCopied, setIsCopied] = useState(false)
  const weekDayFormat = {
    sameDay: '[Heute], DD.MM.YYYY, HH:mm',
    nextDay: '[Morgen], DD.MM.YYYY, HH:mm',
    nextWeek: 'dddd, DD.MM.YYYY, HH:mm',
    sameElse: 'dddd, DD.MM.YYYY, HH:mm',
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(appointmentToken)
    setIsCopied(true)
  }

  const handleClick = () => {
    navigate('/appointment')
  }

  return (
    <Box color="neutral.800">
      <Heading mt={3} variant="headline.md.medium">
        Termin wurde an den Patienten gesendet
      </Heading>
      <Text mb="10px">
        Ihr Patient hat nun die folgenden Informationen und <br /> Zugangsdaten
        zu dieser Videosprechstunde per E-Mail <br /> erhalten:
      </Text>
      <Flex align="center" my={9}>
        <Icon as={Icons.Clock} w="50px" h="50px" />
        <Flex direction="column" ml="20px">
          <Text>Zeitpunkt der Videosprechstunde:</Text>
          <Text fontWeight="medium" data-cy="appointment_time">
            {moment(appointmentDate).calendar(null, weekDayFormat)} Uhr
          </Text>
        </Flex>
      </Flex>
      <Text my="15px">
        Folgender Zugangscode zu der Videosprechstunde <br />
        wurde ebenfalls an den Patienten gesendet:
      </Text>
      <Box bg="neutral.50" align="center" borderRadius="xs">
        <Text fontSize="xl" py="10px">
          {appointmentToken}
        </Text>
      </Box>
      <Flex direction="column" mt="70px">
        <Button w="xs" mb="5px" variant="outline" onClick={handleCopyClick}>
          Code kopieren
        </Button>
        {isCopied && (
          <Alert status="success" w="xs" h="15px" borderRadius="md">
            <AlertIcon />
            <AlertDescription>Zugangscode kopiert!</AlertDescription>
          </Alert>
        )}
        <Spacer space={20} />
        <Button w="xs" onClick={handleClick}>
          Zu meiner Termin-Übersicht
        </Button>
      </Flex>
    </Box>
  )
}

export default Confirmation
