import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg'
import Spacer from '../Spacer'
import './Error.scss'

const Error = ({ title, description }) => (
  <div className="Error">
    <div className="Error__icon">
      <WarningIcon />
    </div>
    <Spacer space={10} />
    {title && (
      <div className="Error__title">
        <p>{title}</p>
      </div>
    )}
    {description && (
      <div className="Error__description">
        <p>{description}</p>
      </div>
    )}
  </div>
)

export default Error
