import { API_CLIENT_ID, API_DOMAIN, REQUEST_HEADER_JSON } from '../../constants'

const getToken = ({ username, password }) => ({
  url: `${API_DOMAIN}/v1/authentication/oauth2/token/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  payload: {
    username,
    password,
    client_id: API_CLIENT_ID,
    grant_type: 'password',
  },
})

const renewToken = ({ refreshToken }) => ({
  url: `${API_DOMAIN}/v1/authentication/oauth2/token/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  payload: {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
    client_id: API_CLIENT_ID,
  },
})

const revokeToken = ({ accessToken }) => ({
  url: `${API_DOMAIN}/v1/authentication/oauth2/revoke_token/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  payload: {
    token: accessToken,
    client_id: API_CLIENT_ID,
  },
  accessToken,
})

const getUser = ({ accessToken }) => ({
  url: `${API_DOMAIN}/v1/accounts/whoami/`,
  method: 'GET',
  header: REQUEST_HEADER_JSON,
  accessToken,
})

const getAppointments = ({
  accessToken,
  pageSize = 20,
  page = 1,
  upcoming,
  ascendingOrdering = true,
}) => ({
  url: `${API_DOMAIN}/v1/consultations/?page=${page}&page_size=${pageSize}&upcoming=${upcoming}&ordering=${
    ascendingOrdering ? 'start_time' : '-start_time'
  }`,
  method: 'GET',
  header: REQUEST_HEADER_JSON,
  accessToken,
})

const getAppointment = ({ id, accessToken }) => ({
  url: `${API_DOMAIN}/v1/consultations/${id}/`,
  method: 'GET',
  header: REQUEST_HEADER_JSON,
  accessToken,
})

const completeConsultation = ({ id, accessToken }) => ({
  url: `${API_DOMAIN}/v1/consultations/${id}/complete/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  accessToken,
})

const joinRoom = ({ accessToken, joinBefore }) => ({
  url: `${API_DOMAIN}/v1/halo/consultation/join_consultation/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  accessToken,
  payload: {
    tan_code: accessToken,
    join_before: joinBefore,
  },
})

// Doctor join endpoint
const joinConsultation = ({ accessToken, id }) => ({
  url: `${API_DOMAIN}/v1/consultations/${id}/join/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  accessToken,
})

// Patient | Exchange tan with session
// credentials: sessionId and token
const getSessionCredentials = ({ tan }) => ({
  url: `${API_DOMAIN}/v1/consultations/join/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  payload: { tan_code: tan },
})

const createAppointment = ({ email, name, date, accessToken }) => ({
  url: `${API_DOMAIN}/v1/consultations/`,
  method: 'POST',
  header: REQUEST_HEADER_JSON,
  accessToken,
  payload: {
    patient_email: email,
    patient_name: name,
    start_time: date,
    notify_patient: true,
    external_id: null,
  },
})

export default {
  getToken,
  renewToken,
  revokeToken,
  getUser,
  getAppointments,
  getAppointment,
  joinRoom,
  createAppointment,
  joinConsultation,
  completeConsultation,
  getSessionCredentials,
}
