import { useEffect, useState } from 'react'

const { localStorage } = window

export const useLocalStorage = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue
    }
    const item = localStorage.getItem(key)
    return item !== null && JSON.parse(item)
  })

  useEffect(() => {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }, [value, key])

  return [value, setValue]
}

export const useAppState = () => {
  const [hasFocus, setHasFocus] = useState(true)

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setHasFocus(true)
      return
    }
    setHasFocus(false)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', () => setHasFocus(true))
    window.addEventListener('blur', () => setHasFocus(false))

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', () => setHasFocus(false))
      window.removeEventListener('blur', () => setHasFocus(false))
    }
  }, [])

  return hasFocus
}

export const useSessionStorage = (key: string, initialValue = null) => {
  const [state, setState] = useState(() => {
    const item = window.sessionStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  })

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}
