export const ERRORS = {
  already_completed: 'Die Konsultation ist bereits abgeschlossen.',
  awaiting_doctor:
    'Ihr Arzt / Ihre Ärztin hat die Videosprechstunde noch nicht begonnen.',
  consultation_not_found: 'Ungültige TAN',
  tan_code_expired:
    'Ihr Termin begann vor mehr als 30 Minuten. Sie können der Videosprechstunde nicht mehr beitreten. Kontaktieren Sie Ihren Arzt, um einen neuen Termin zu erhalten.',
  default:
    'Ihr Arzt / Ihre Ärztin hat die Videosprechstunde noch nicht begonnen.',
}
