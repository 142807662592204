const overrides = {
  field: { height: 'unset', p: '0.75em 1em' },
}

const Input = {
  baseStyle: {
    field: {
      lineHeight: '1',
    },
  },
  sizes: {
    xs: overrides,
    sm: overrides,
    md: overrides,
    lg: overrides,
  },
  defaultProps: {
    focusBorderColor: 'primary.500',
    errorBorderColor: 'system.error',
  },
  variants: {
    outline: {
      field: {
        borderColor: 'border.secondary',
        _placeholder: {
          color: 'content.secondary',
        },
        _hover: {
          borderColor: 'border.secondary',
        },
        _disabled: {
          bg: 'surface.disabled',
          color: 'content.disabled',
          borderColor: 'border.secondary',
          opacity: 1,
        },
      },
    },
  },
}

export default Input
