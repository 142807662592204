import { useRef } from 'react'
import classNames from 'classnames'

import styles from './PinInputField.module.scss'

const noop = () => null

function PinInputField({
  placeholder,
  hidePlaceholderWhenActive,
  onBlur,
  error,
  onComplete,
  length,
  innerRef,
  ...props
}) {
  const refs = useRef([])
  const focusSiblingElement = (index) => {
    const sibling = refs.current[index]
    if (sibling) {
      sibling.focus()
    }
  }
  if (length) {
    return (
      <div className={styles.container}>
        {Array.from({ length }, (_, index) => {
          return (
            <div
              key={index}
              className={classNames(styles.pinInputField, {
                [styles.error]: error,
              })}
            >
              <input
                // eslint-disable-next-line
                autoFocus={index === 0}
                ref={(element) => {
                  refs.current.push(element)
                }}
                onBlur={noop}
                className={classNames(styles.pinInputField, {
                  [styles.error]: error,
                })}
                onChange={(event) => {
                  if (event.target.value) {
                    focusSiblingElement(index + 1)
                    if (props.onChange) {
                      props.onChange(index, event.target.value)
                    }
                  }
                  if (index + 1 === length && event.target.value) {
                    onComplete()
                  }
                }}
                type="text"
                maxLength="1"
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.pinInputField, {
        [styles.error]: error,
      })}
    >
      <input ref={innerRef} type="text" maxLength="1" {...props} />
    </div>
  )
}

export default PinInputField
