import { partition } from 'lodash'
import moment from 'moment'
import {
  DEVICE_TYPE_CAMERA,
  DEVICE_TYPE_MICROPHONE,
  GENDER_DIVERSE,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_UNKNOWN,
  STATUS_INITIALIZED,
  STATUS_IN_PROGRESS,
} from '../constants'

export const queryParams = (param) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(param) || null
}

export const getGreeting = (genderId) => {
  const greeting = {
    [GENDER_MALE]: 'Herr',
    [GENDER_FEMALE]: 'Frau',
    [GENDER_UNKNOWN]: 'Unbekannt',
    [GENDER_DIVERSE]: 'Divers',
  }
  return greeting[genderId]
}

export const allowVideoConsultation = (status) => {
  return [STATUS_INITIALIZED, STATUS_IN_PROGRESS].includes(status)
}

export const appointmentStatus = (currentTime, startTime, status) => {
  if (status == null) {
    return null
  }

  if (moment(currentTime).isSame(moment(startTime), 'day')) {
    if (
      allowVideoConsultation(status) &&
      moment(currentTime).isAfter(moment(startTime).subtract(5, 'minutes')) &&
      moment(currentTime).isBefore(startTime)
    ) {
      return 'soon'
    }

    if (
      allowVideoConsultation(status) &&
      moment(currentTime).isAfter(startTime) &&
      moment(currentTime).isBefore(moment(startTime).add(15, 'minutes'))
    ) {
      return 'now'
    }
  }

  return null
}

export const getDefaultDevice = (devices) => {
  const [videoInputDevices, audioInputDevices] = partition(
    devices.filter(
      (device) =>
        device.kind === DEVICE_TYPE_MICROPHONE ||
        device.kind === DEVICE_TYPE_CAMERA,
    ),
    ({ kind }) => kind === DEVICE_TYPE_CAMERA,
  )

  const videoInput =
    videoInputDevices.find((device) => device.deviceId === 'default') ||
    videoInputDevices[0]
  const audioInput =
    audioInputDevices.find((device) => device.deviceId === 'default') ||
    audioInputDevices[0]

  return [audioInput, videoInput]
}

export const getPartnerSupportEmail = (partnerID) => {
  const emails = new Map([
    ['adac', 'adac@teleclinic.zendesk.com'],
    ['dak', 'dak@teleclinic.zendesk.com'],
  ])
  return emails.get(partnerID) || 'kundenservice@teleclinic.com'
}
