import { Box, Button, Heading, Icon, Text, VStack } from '@chakra-ui/react'
import { ReactComponent as Warning } from 'assets/icons/Warning.svg'
import { ModalDrawer } from 'components/presentational'

export function EndCallModal({ isOpen, onClose, onDisconnect, isDoctor }) {
  return (
    <ModalDrawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <Box align="center" py="8" px="6">
        <Icon as={Warning} boxSize="8" fill="semantics.warning" mb="4" />

        {isDoctor ? (
          <Heading variant="headline.sm.medium">
            Wollen Sie das Gespräch mit dem Patienten beenden?
          </Heading>
        ) : (
          <>
            <Heading variant="headline.md.medium">
              Wollen Sie die Sprechstunde wirklich beenden?
            </Heading>
            <Text mt="3" variant="body.md.regular">
              Wenn Sie das Gespräch verlassen, ist der Termin mit Ihrem Arzt
              abgeschlossen.
            </Text>
          </>
        )}

        <VStack mt="6" align="stretch">
          <Button onClick={onDisconnect}>Videoanruf Beenden</Button>
          <Button onClick={onClose} variant="outline">
            Zurück
          </Button>
        </VStack>
      </Box>
    </ModalDrawer>
  )
}
