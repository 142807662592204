import { LocationContext } from 'components/contextProvider'
import Page from 'components/view/Page'
import { useContext } from 'react'
import { Appointments } from './components'

const Dashboard = () => {
  const { filter, page, searchQuery } = useContext(LocationContext)

  return (
    <Page>
      {({ setLoading }) => (
        <Appointments
          setLoading={setLoading}
          filter={filter}
          page={page}
          searchQuery={searchQuery}
        />
      )}
    </Page>
  )
}

export default Dashboard
