import { get } from 'lodash'

export const userEntity = (payload) => {
  if (!payload) return null
  const firstName = get(payload, 'first_name', '')
  const lastName = get(payload, 'last_name', '')

  return {
    id: get(payload, 'id', ''),
    fullName: `${firstName} ${lastName}`,
    email: get(payload, 'email', ''),
  }
}

export const appointmentListEntity = (payload) => {
  return payload.map((appointment) => {
    return {
      id: get(appointment, 'id', ''),
      status: get(appointment, 'status', null),
      date: get(appointment, 'start_time', ''),
      duration: get(appointment, 'duration', ''),
      patientJoinedAt: get(appointment, 'patient_joined_at', ''),
      name: get(appointment, 'patient_name', ''),
    }
  })
}

export const appointmentEntity = (payload) => {
  return {
    id: get(payload, 'id', ''),
    status: get(payload, 'status', null),
    date: get(payload, 'start_time', ''),
    duration: get(payload, 'duration', 0),
    name: get(payload, 'patient_name', ''),
    email: get(payload, 'patient_email', ''),
    patientJoinedAt: get(payload, 'patient_joined_at', ''),
  }
}

export const createdAppointmentEntity = (payload) => {
  return {
    id: get(payload, 'id', ''),
    token: get(payload, 'tan_code', ''),
  }
}

export const completedConsultationEntity = (payload) => {
  return {
    status: get(payload, 'status', null),
    patientJoinedAt: get(payload, 'patient_joined_at', null),
    duration: get(payload, 'duration', 0),
    doctorJoinedAt: get(payload, 'doctor_joined_at', null),
    completedAt: get(payload, 'completed_at', null),
  }
}

export const twoFactorAuth = (payload) => {
  return {
    required: get(payload, 'required', false),
    sent: get(payload, 'sent', false),
    mobileNumber: get(payload, 'mobile', ''),
  }
}
