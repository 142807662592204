import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react'
import { ReactComponent as EyeOff } from 'assets/icons/EyeOff.svg'
import { Icons } from 'components'

export const PasswordInput = ({ field, form, ...props }) => {
  const [showPassword, setShowPassword] = useBoolean()

  return (
    <InputGroup>
      <Input type={showPassword ? 'text' : 'password'} {...field} {...props} />
      <InputRightElement h="100%" px="1em">
        <Icon
          as={showPassword ? EyeOff : Icons.Eye}
          boxSize="5"
          onClick={setShowPassword.toggle}
        />
      </InputRightElement>
    </InputGroup>
  )
}
