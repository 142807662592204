import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { NetworkContext } from 'components/contextProvider'
import { Modal } from 'components/presentational'
import Error from 'components/presentational/Error'
import Page from 'components/view/Page'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appointmentEntity } from 'store/entities'
import { useLocalStorage } from 'utils/hooks.ts'
import {
  isAfterAppointmentMinStartTime,
  isBeforeAppointmentMaxStartTime,
} from './utils'

const WaitingRoom = () => {
  const [devices] = useLocalStorage('devices')
  const videoRef = useRef(null)
  const { appointmentId } = useParams()
  const [error, setError] = useState({ hasError: false })
  const [appointment, setAppointment] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const { getAppointment, joinConsultation } = useContext(NetworkContext)
  const navigate = useNavigate()

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: devices?.videoInput?.deviceId } })
      .then((stream) => {
        const video = videoRef.current
        video.srcObject = stream
      })
      .catch(() => {
        setError({
          hasError: true,
          title: 'Etwas ist schiefgelaufen',
          description: 'Bitte laden Sie die Seite erneut',
        })
      })
  }

  useEffect(() => {
    const instance = videoRef.current
    getVideo()

    return () => {
      if (instance.srcObject) {
        instance.srcObject.getTracks().forEach((track) => track.stop())
      }
    }
  }, [videoRef])

  useEffect(() => {
    getAppointment({ id: appointmentId })
      .then((res) => {
        const mappedAppointment = appointmentEntity(res)
        setAppointment(mappedAppointment)

        if (mappedAppointment.status > 1) {
          const { status, duration } = mappedAppointment
          const params = new URLSearchParams({
            appointmentStatus: status,
            appointmentDuration: duration,
          }).toString()

          navigate(`/finished?${params}`, { replace: true })
        }
      })
      .catch(() => {
        return setError({
          hasError: true,
          title: 'Ein Fehler ist aufgetreten',
          description: 'Die Sprechstunde konnte nicht aufgerufen werden.',
        })
      })
      .finally(() => setLoading(false))
  }, [])

  const onClickBack = () => {
    navigate('/')
  }

  const setCurrentAppointment = (id) =>
    window.localStorage.setItem('currentAppointment', id)

  const roomNavigation = async (data) => {
    const room = await joinConsultation({ id: data.id })
    setCurrentAppointment(appointmentId)
    navigate('/room', {
      state: {
        id: room.consultation_id,
        apiKey: room.application_id,
        sessionId: room.session_id,
        token: room.token,
      },
    })
  }

  const onClick = () => {
    const currentTime = moment()

    if (isBeforeAppointmentMaxStartTime(currentTime, appointment.date)) {
      return setError({
        hasError: true,
        title: 'Sie sind etwas zu früh dran',
        description:
          'Sie können dem Gespräch erst 2 Minuten vor Beginn der Konsultation beitreten.',
      })
    }
    if (isAfterAppointmentMinStartTime(currentTime, appointment.date)) {
      return setError({
        hasError: true,
        title: 'Sie haben sich verspätet',
        description: 'Diese Videosprechstunde kann nicht mehr stattfinden.',
      })
    }

    return roomNavigation(appointment)
  }

  return (
    <Page>
      {() => (
        <Flex width="100%" justify="center" align="center" bg="neutral.0">
          <Modal
            isOpen={error.hasError}
            onClose={() => setError({ hasError: false })}
          >
            <Error title={error.title} description={error.description} />
          </Modal>

          <Box
            as="video"
            ref={videoRef}
            width="550px"
            autoPlay
            borderRadius="md"
          />
          <Flex direction="column" width="530px" ml={8} justify="center">
            <Text
              data-cy="waitingroom_header"
              fontWeight="bold"
              fontSize="2xl"
              color="neutral.800"
            >
              Möchten Sie die Videosprechstunde starten?
            </Text>
            <Flex justify="center">
              <Button
                data-cy="start_button"
                width="100%"
                variant="solid"
                mr="1rem"
                onClick={onClick}
                isLoading={isLoading}
              >
                Jetzt Video starten
              </Button>

              <Button data-cy="back_button" width="100%" onClick={onClickBack}>
                Zurück
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Page>
  )
}

export default WaitingRoom
