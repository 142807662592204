import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    w: '24px',
    h: '24px',
    borderColor: 'neutral.500',
    transition: 'all 0.3s ease',
    borderRadius: '6px',
    borderWidth: '1.8px',
    boxShadow:
      'inset 2px 2px 0px white,' +
      'inset 2px -2px 0px white,' +
      'inset -2px 2px 0px white,' +
      'inset -2px -2px 0px white',
  },
  container: {
    alignItems: 'start',
  },
  icon: {
    display: 'none',
  },
})

const defaultProps = {
  colorScheme: 'primary',
}

export default defineMultiStyleConfig({ baseStyle, defaultProps })
