import { ColorModeScript } from '@chakra-ui/react'
import { createRoot } from 'react-dom/client'
import theme from 'theme'
import App from './App'
import './index.scss'

createRoot(document.getElementById('root')).render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </>,
)
