import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  Heading,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react'
import { ReactComponent as CameraOn } from 'assets/icons/CameraOn.svg'
import { ReactComponent as MicOn } from 'assets/icons/MicOn.svg'

export function PermissionsModal({ isOpen, onClose }) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      size="full"
      closeOnEsc={false}
    >
      <DrawerContent>
        <DrawerBody>
          <Center h="100%">
            <Box align="center" py="8" px="6" maxW="350px">
              <HStack mb="8" justify="center" spacing={4}>
                <Icon as={CameraOn} boxSize="8" fill="primary.500" />
                <Icon as={MicOn} boxSize="8" fill="primary.500" />
              </HStack>
              <Heading variant="headline.md.medium">
                Bitte aktivieren Sie Kamera und Mikrofon
              </Heading>
              <Text mt="3" variant="body.md.regular">
                Für die Videosprechstunde sind Kamera und Mikrofon zwingend
                notwendig
              </Text>
            </Box>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
