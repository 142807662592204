import { Flex, Icon, Text, VStack } from '@chakra-ui/react'
import { Icons } from 'components'
import moment from 'moment'
import { appointmentStatus } from 'utils/utils'
import { FILTER } from '../constants'

const AppointmentTeaser = ({
  dateTime,
  name,
  onClick,
  caseId,
  status,
  filter,
}) => {
  const displayIndicator = appointmentStatus(new Date(), dateTime, status)

  return (
    <Flex
      direction="column"
      border="1px solid #dedbd9"
      borderLeft="3px solid #dedbd9"
    >
      <Flex
        onClick={() => filter === FILTER.UPCOMING && onClick()}
        tabIndex={0}
        align="center"
        height="81px"
        px="10px"
        my="2px"
        _hover={{
          background: '#f4f4f4',
          cursor: 'pointer',
        }}
        justify="space-between"
      >
        <Flex gap={12} fontSize="sm" align="center">
          <Flex align="center" gap={2}>
            <Icon as={Icons.SimpleClock} fill="primary.500" />
            <VStack gap={0} align="start">
              {displayIndicator === 'soon' && (
                <Text fontSize="2xs" color="red.400">
                  Beginnt in Kürze
                </Text>
              )}
              {displayIndicator === 'now' ? (
                <Text color="red.400">Jetzt</Text>
              ) : (
                <Text>{`${moment(dateTime).format('HH:mm')} Uhr`}</Text>
              )}
            </VStack>
          </Flex>
          <Text>{moment(dateTime).format('DD.MM.YYYY')}</Text>
          <Text>{`${name}`}</Text>
          {caseId != null && <Text>Fall-Nr: {caseId}</Text>}
        </Flex>
        {filter === FILTER.UPCOMING && (
          <Icon
            position="relative"
            as={Icons.ChevronRight}
            fill="primary.500"
            boxSize={5}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default AppointmentTeaser
