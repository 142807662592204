import { Button, Flex, Heading, Icon } from '@chakra-ui/react'
import { Icons } from 'components'

const EmptyState = ({ text }) => {
  return (
    <Flex direction="column" align="center">
      <Icon as={Icons.CalendarDetailed} boxSize="100px" />
      <Heading as="h1" size="lg" mt={10}>
        {text}
      </Heading>
      <Button mt={20} onClick={() => window.location.reload()}>
        Neu laden
      </Button>
    </Flex>
  )
}

export default EmptyState
