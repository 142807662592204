import { orderBy } from 'lodash'
import moment from 'moment'

export const getCurrentAppointment = () =>
  window.localStorage.getItem('currentAppointment')
export const getNextAppointment = () =>
  window.localStorage.getItem('nextAppointment')
export const setNextAppointment = (id) =>
  window.localStorage.setItem('nextAppointment', id)

export const appointmentIsLaterToday = (
  currentDateTime,
  appointmentDateTime,
) => {
  const momentObj = moment(appointmentDateTime)
  return (
    momentObj.isSame(currentDateTime, 'day') &&
    momentObj.isAfter(currentDateTime)
  )
}

export const orderByDateTime = (appointments) =>
  orderBy(
    appointments,
    (appointment) => moment(appointment.dateTime).format('YYYYMMDD HHmm'),
    ['asc'],
  )
