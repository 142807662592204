import { Button, Heading } from '@chakra-ui/react'
import { Spacer } from 'components/presentational'
import Page from 'components/view/Page'
import { Component } from 'react'
import './ErrorBoundary.scss'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  componentDidCatch(error) {
    if (error) {
      window.rum?.recordError(error)
      this.setState({ error: true })
    }
  }

  componentWillUnmount() {
    this.setState({ error: false })
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    if (error) {
      return (
        <Page>
          {() => (
            <div className="ErrorBoundary">
              <Spacer space={100} />
              <Heading>Upps, da ist etwas schief gelaufen.</Heading>
              <Spacer space={50} />
              <Button onClick={() => window.location.reload()}>
                Nochmal versuchen
              </Button>
              <Spacer space={20} />
              <a href="mailto:docsupport@teleclinic.com">
                Technische Probleme? Melden Sie sich bei uns!
              </a>
            </div>
          )}
        </Page>
      )
    }

    return children
  }
}

export default ErrorBoundary
