import moment from 'moment'

export const calculateAppointmentTime = (appointmentDuration) => {
  const timeInSeconds = moment
    .duration({ seconds: appointmentDuration })
    .seconds()
  const timeInMinutes = moment
    .duration({ seconds: appointmentDuration })
    .minutes()

  if (appointmentDuration < 60) {
    return `${timeInSeconds} Sekunden`
  }
  if (appointmentDuration >= 120) {
    return `${timeInMinutes} Minuten`
  }
  return `${timeInMinutes} Minute`
}
