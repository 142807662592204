import { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { NetworkContext } from 'components/contextProvider'
import { userEntity } from 'store/entities'

export const UserContext = createContext()

function UserContextProvider({ children }) {
  const [user, setUser] = useState(userEntity())
  const { isAuthenticated, getUser } = useContext(NetworkContext)

  useEffect(() => {
    let mounted = true
    if (mounted && isAuthenticated) {
      getUser().then((payload) => {
        setUser(userEntity(payload))
      })
    } else {
      setUser(userEntity())
    }
    return () => {
      mounted = false
    }
  }, [isAuthenticated, getUser])

  const context = useMemo(() => {
    return { user, setUser }
  }, [user, setUser])

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default UserContextProvider
