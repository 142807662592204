import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Icons } from 'components'
import { Fragment } from 'react'

const ParticipantDrawer = ({
  onClose,
  isOpen,
  participantList,
  handleClick,
}) => (
  <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />

      <DrawerHeader borderBottomWidth="1px" color="neutral.450">
        Teilnehmer
      </DrawerHeader>
      <DrawerBody>
        <Text fontSize="xs" fontWeight="medium" color="neutral.550">
          Im Meeting
        </Text>
        <Flex direction="column" border="1px solid #DEDBD9" borderRadius="lg">
          <Flex
            pl={2}
            pr={5}
            py={2}
            justify="space-between"
            borderBottom="1px solid #DEDBD9"
            fontSize="sm"
            color="neutral.450"
          >
            <Text>Im Meeting</Text>
            <Text>{participantList.length}</Text>
          </Flex>
          <Flex direction="column" pl={2} pr={4} py={2}>
            {participantList.map((participant) => (
              <Fragment key={participant.id}>
                <Flex align="center" justify="space-between" mt={3}>
                  <Text>{participant.name}</Text>
                  {participant.type === 'subscriber' && (
                    <Tooltip hasArrow label="Teilnehmer vom Gespräch entfernen">
                      <Icon
                        as={Icons.Trash}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => handleClick(participant.connection)}
                      />
                    </Tooltip>
                  )}
                </Flex>
                {participant.type === 'moderator' && (
                  <Text fontSize="xs" color="neutral.550">
                    Meeting Host
                  </Text>
                )}
              </Fragment>
            ))}
          </Flex>
        </Flex>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
)

export default ParticipantDrawer
