import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import { Icons } from 'components'
import { NetworkContext } from 'components/contextProvider'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import UsernamePasswordSchema from 'services/validation/schemas/authenticationSchema'
import { twoFactorAuth as twoFactorAuthEntity } from 'store/entities'
import { queryParams } from 'utils/utils'
import { PasswordInput } from '../elements'

const noop = () => null

const responseToken = (errorResponse) => {
  switch (errorResponse.status) {
    case 401:
      return {
        text: 'Sie sind nicht berechtigt, auf diese Anwendung zuzugreifen',
      }
    case 400: {
      return {
        text: 'Login fehlgeschlagen',
      }
    }
    case 429:
      return {
        text: 'Sie haben bereits drei neue SMS Codes erhalten. Diese Funktion ist 30 Minuten gesperrt. Danach können Sie es erneut versuchen.',
      }
    default:
      return {
        text: 'Ein unbekannter Fehler ist aufgetreten',
      }
  }
}

const responseVerifyToken = (errorResponse) => {
  if (errorResponse.status === 401) {
    return {
      text: 'Code falsch',
    }
  }
  return {
    text: 'Ein unbekannter Fehler ist aufgetreten',
  }
}

const Authentication = () => {
  const { getToken, setAuth, verifyToken } = useContext(NetworkContext)
  const [twoFactorAuth, setTwoFactorAuth] = useState(false)
  const [credentials, setCredentials] = useState(null)
  const sessionExpired = Boolean(queryParams('session-expired'))

  const onSubmit = ({ username, password }, { setSubmitting, setErrors }) => {
    setCredentials({ username, password })

    setSubmitting(true)
    getToken({ username, password }).then(
      (res) => {
        setSubmitting(false)
        const twoFactorAuthResponse = twoFactorAuthEntity(res['2FA'])
        if (!twoFactorAuthResponse.required) {
          setCredentials(null)
          setAuth(res)
          return
        }
        setTwoFactorAuth(twoFactorAuthResponse)
      },
      (errorResponse) => {
        setSubmitting(false)
        setErrors(responseToken(errorResponse))
      },
    )
  }

  return (
    <Flex
      justify="center"
      flex="1"
      py={{ base: '0px', md: '100px' }}
      maxH={{ base: '100%', md: '800px' }}
    >
      <Flex
        bg="neutral.0"
        direction="column"
        borderRadius="lg"
        w="100%"
        maxW="600px"
      >
        <Flex
          direction="column"
          bg="primary.500"
          justify="center"
          align="center"
          borderTopRadius={{ md: 'lg' }}
          p="10px"
        >
          <Icon as={Icons.VideoLogo} fill="neutral.0" boxSize="60px" />
          <Text fontSize="xl" color="neutral.0">
            <Text as="span" fontWeight="semibold">
              tele
            </Text>
            clinic.video
          </Text>
        </Flex>
        <Flex direction="column" align="center" px="100px" mt="20px">
          {twoFactorAuth?.required ? (
            <>
              <Heading variant="headline.md.bold">Code eingeben</Heading>
              {twoFactorAuth.mobileNumber && (
                <Text textAlign="center">
                  Wir haben den Sicherheitscode an diese Nummer gesendet:{' '}
                  <i>
                    *******
                    {twoFactorAuth.mobileNumber}
                  </i>
                </Text>
              )}
              <Flex align="center" justify="center">
                <Formik
                  initialValues={{ tan: Array(7).fill('') }}
                  onSubmit={({ tan }, { setErrors, setSubmitting }) => {
                    const tanCode = tan.join('')
                    setSubmitting(true)

                    verifyToken({
                      username: credentials.username,
                      password: credentials.password,
                      tanCode,
                    }).then(
                      (res) => {
                        setAuth(res)
                        setCredentials(null)
                      },
                      (error) => {
                        setErrors(responseVerifyToken(error))
                      },
                    )
                    setSubmitting(false)
                  }}
                >
                  {({ errors, values, resetForm, setErrors, isSubmitting }) => {
                    const tanCode = values.tan.join('')
                    return (
                      <Form>
                        <FieldArray name="tan">
                          {() => (
                            <Center>
                              <HStack mt={{ base: '1rem', md: '2rem' }}>
                                <PinInput
                                  placeholder=""
                                  focusBorderColor="primary.500"
                                  errorBorderColor="red"
                                  isInvalid={errors.text}
                                  size={{ base: 'md', md: 'lg' }}
                                >
                                  {values.tan.map((_, index) => (
                                    <Field
                                      borderColor="neutral.500"
                                      autoFocus={index === 0}
                                      as={PinInputField}
                                      key={index}
                                      name={`tan[${index}]`}
                                      error={errors}
                                      onBlur={noop}
                                    />
                                  ))}
                                </PinInput>
                              </HStack>
                            </Center>
                          )}
                        </FieldArray>
                        {errors.text && (
                          <Text
                            fontWeight="light"
                            fontSize="sm"
                            color="semantics.error"
                          >
                            {errors.text}
                          </Text>
                        )}

                        <Center mb="40px" mt="20px">
                          <Button
                            variant="unstyled"
                            type="button"
                            color="primary.500"
                            onClick={() => {
                              resetForm()
                              getToken({
                                username: credentials.username,
                                password: credentials.password,
                              }).catch((errorResponse) => {
                                setErrors(responseToken(errorResponse))
                              })
                            }}
                          >
                            Code erneut senden
                          </Button>
                        </Center>
                        <Center mb="20px">
                          <Button
                            isDisabled={tanCode.length < 7 || isSubmitting}
                            type="submit"
                          >
                            Anmelden
                          </Button>
                        </Center>
                        {errors.text && (
                          <Text
                            as={Link}
                            textAlign="center"
                            href="mailto:docsupport@teleclinic.com"
                          >
                            Technische Probleme? Melden Sie sich bei uns!
                          </Text>
                        )}
                      </Form>
                    )
                  }}
                </Formik>
              </Flex>
            </>
          ) : (
            <>
              <Heading variant="headline.md.medium">Anmeldung</Heading>
              <Text>für medizinisches Personal</Text>
              {sessionExpired && (
                <Text
                  fontWeight="light"
                  fontSize="sm"
                  padding="10px"
                  color="semantics.error"
                  textAlign="center"
                >
                  Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.
                </Text>
              )}

              <Formik
                initialValues={{
                  username: '',
                  password: '',
                }}
                validationSchema={UsernamePasswordSchema}
                onSubmit={onSubmit}
              >
                {({ errors, isSubmitting, handleBlur }) => (
                  <Form style={{ width: '100%', marginTop: '40px' }}>
                    <Flex direction="column" align="center">
                      <Flex direction="column" w="100%" mb="30px">
                        <FormControl>
                          <FormLabel>E-Mail Adresse*</FormLabel>
                          <Field
                            data-cy="email"
                            type="email"
                            name="username"
                            placeholder="E-Mail Adresse*"
                            onBlur={handleBlur}
                            as={Input}
                          />
                          <ErrorMessage
                            name="username"
                            render={(msg) => (
                              <Text color="semantics.error" fontSize="xs">
                                {msg}
                              </Text>
                            )}
                          />
                        </FormControl>
                      </Flex>
                      <Flex direction="column" w="100%" mb={10}>
                        <FormControl>
                          <FormLabel>Passwort*</FormLabel>
                          <Field
                            data-cy="password"
                            name="password"
                            placeholder="Passwort*"
                            onBlur={handleBlur}
                            as={PasswordInput}
                          />
                          <ErrorMessage
                            name="password"
                            render={(msg) => (
                              <Text color="semantics.error" fontSize="xs">
                                {msg}
                              </Text>
                            )}
                          />
                        </FormControl>
                      </Flex>
                      {errors.text && (
                        <Text
                          mt="15px"
                          fontWeight="light"
                          fontSize="sm"
                          color="semantics.error"
                        >
                          {errors.text}
                        </Text>
                      )}

                      <Button
                        my="20px"
                        type="submit"
                        isDisabled={
                          isSubmitting || Object.keys(errors).length > 0
                        }
                        // onMouseDown is needed, otherwise button will need to be clicked twice because of handleBlur
                        onMouseDown={(event) => {
                          event.preventDefault()
                        }}
                      >
                        Anmelden
                      </Button>

                      {errors.text && (
                        <Text
                          as={Link}
                          textAlign="center"
                          href="mailto:docsupport@teleclinic.com"
                        >
                          Technische Probleme? Melden Sie sich bei uns!
                        </Text>
                      )}
                    </Flex>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Authentication
