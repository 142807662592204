import {
  AspectRatio,
  Box,
  Flex,
  Slide,
  useBoolean,
  useBreakpointValue,
  useDimensions,
  useDisclosure,
} from '@chakra-ui/react'
import OT from '@opentok/client'
import { NetworkContext, UserContext } from 'components/contextProvider'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { completedConsultationEntity } from 'store/entities'
import { EndCallModal, PermissionsModal, Settings } from './components'
import {
  CameraFlipToggle,
  CameraToggle,
  HangupButton,
  MicrophoneToggle,
  ParticipantDrawer,
  ParticipantsListButton,
  RoomControls,
  SettingsButton,
} from './elements'

export default function Room() {
  const navigate = useNavigate()
  const location = useLocation()

  const endCallModal = useDisclosure()
  const settingsModal = useDisclosure()
  const permissionsModal = useDisclosure()
  const participantsDrawer = useDisclosure()

  const { user } = useContext(UserContext)
  const { completeConsultation } = useContext(NetworkContext)

  const session = useRef()
  const publisher = useRef()

  const [participantList, setParticipantList] = useState([])
  const [subscriberCount, setSubscriberCount] = useState(0)
  const [isCameraOn, { toggle: toggleCamera }] = useBoolean(true)
  const [isMicOn, { toggle: toggleMic }] = useBoolean(true)
  const [isFocusedMode] = useBoolean(false)

  const controlsRef = useRef()
  const ctrlDimensions = useDimensions(controlsRef)

  const isDoctor = user !== null
  const isMobile = useBreakpointValue({ base: true, md: false })
  const isModerator = session.current?.capabilities?.forceDisconnect === 1
  const { id, apiKey, sessionId, token } = location.state
  const layout = { columns: Math.ceil(Math.sqrt(subscriberCount)) }

  // Handler for when a participant (other
  // than self) is diconnected from the call
  const onConnectionDestroyed = (event) => {
    setParticipantList((prev) =>
      prev.filter(
        (participant) => participant.id !== event.connection.connectionId,
      ),
    )
  }

  // Handler for when self is disconnected from the call
  const onSessionDisconnected = (event) => {
    if (event.reason === 'forceDisconnected') {
      navigate('/finished', { replace: true })
    }
  }

  const onStreamDestroyed = () => {
    setSubscriberCount((prev) => prev - 1)
  }

  const onStreamCreated = (event) => {
    setSubscriberCount((prev) => prev + 1)
    setParticipantList((prev) => [
      ...prev,
      {
        id: event.stream.connection.id,
        name: 'Patient',
        connection: event.stream.connection,
        type: 'subscriber',
      },
    ])

    session.current.subscribe(
      event.stream,
      'subscriber',
      {
        insertMode: 'append',
        width: 'auto',
        height: 'auto',
        style: { buttonDisplayMode: 'off', nameDisplayMode: 'off' },
      },
      () => {},
    )
  }

  const onStreamPropertyChanged = (event) => {
    const { stream, changedProperty, newValue } = event
    if (
      stream.connection.connectionId === session.current.connection.connectionId
    ) {
      if (changedProperty === 'hasVideo') toggleCamera(newValue)
      if (changedProperty === 'hasAudio') toggleMic(newValue)
    }
  }

  const removeParticipantFromCall = (connection) => {
    session.current.forceDisconnect(connection)
  }

  const onDisconnect = async () => {
    session.current.disconnect()
    const url = '/finished'

    if (isDoctor) {
      const completedConsultation = completedConsultationEntity(
        await completeConsultation({ id }),
      )
      const { patientJoinedAt, completedAt, status } = completedConsultation

      return navigate(url, {
        replace: true,
        state: {
          patientJoinedAt,
          completedAt,
          status,
        },
      })
    }

    return navigate(url, {
      replace: true,
    })
  }

  const onAccessDenied = () => {
    permissionsModal.onOpen()
  }

  const onAccessAllowed = () => {
    permissionsModal.onClose()
  }

  useEffect(() => {
    session.current = OT.initSession(apiKey, sessionId)
    session.current.on('connectionDestroyed', onConnectionDestroyed)
    session.current.on('sessionDisconnected', onSessionDisconnected)
    session.current.on('streamPropertyChanged', onStreamPropertyChanged)
    session.current.on('streamCreated', onStreamCreated)
    session.current.on('streamDestroyed', onStreamDestroyed)

    publisher.current = OT.initPublisher('publisher', {
      // name: user?.fullName || name || '',
      insertMode: 'append',
      width: '100%',
      height: '100%',
      style: { buttonDisplayMode: 'off', nameDisplayMode: 'off' },
    })

    publisher.current.on('accessAllowed', onAccessAllowed)
    publisher.current.on('accessDenied', onAccessDenied)

    session.current.connect(token, (error) => {
      if (!error) session.current.publish(publisher.current)
      if (session.current.capabilities.forceDisconnect === 1) {
        setParticipantList((prev) => [
          ...prev,
          {
            id: session.current.connection.connectionId,
            name: user?.fullName || 'Moderator',
            type: 'moderator',
          },
        ])
      }
    })

    return () => {
      session.current.off('connectionDestroyed', onConnectionDestroyed)
      session.current.off('sessionDisconnected', onSessionDisconnected)
      session.current.off('streamPropertyChanged', onStreamPropertyChanged)
      session.current.off('streamCreated', onStreamCreated)
      session.current.off('streamDestroyed', onStreamDestroyed)

      publisher.current.off('accessAllowed', onAccessAllowed)
      publisher.current.off('accessDenied', onAccessDenied)

      publisher.current = null
      session.current = null
    }
  }, [])

  return (
    <Box bg="gray.800" w="100dvw" h="100dvh">
      <Flex
        id="subscriber"
        direction="row"
        flexWrap="wrap"
        justify="center"
        alignContent="stretch"
        overflow="hidden"
        h="100%"
        sx={{
          '& > *': {
            flexBasis: `${100 / layout.columns}%`,
            overflow: 'hidden',
            alignSelf: 'stretch',
          },
        }}
      />

      <AspectRatio
        ratio={isMobile ? 3 / 4 : 16 / 9}
        w={isMobile ? '150px' : '240px'}
        position="absolute"
        right="0"
        borderRadius="lg"
        transition="all 0.3s ease"
        overflow="hidden"
        boxShadow="md"
        m="4"
        bottom={
          !isMobile || isFocusedMode
            ? '0'
            : `${ctrlDimensions?.contentBox.height}px`
        }
      >
        <Flex id="publisher" />
      </AspectRatio>

      <Slide
        direction="bottom"
        ref={controlsRef}
        in={!isFocusedMode}
        style={{ zIndex: 10 }}
      >
        <Flex justify="center">
          <RoomControls>
            <CameraToggle
              isActive={isCameraOn}
              onClick={() => {
                const { publishVideo, stream } = publisher.current
                publishVideo(!stream.hasVideo)
              }}
            />

            <MicrophoneToggle
              isActive={isMicOn}
              onClick={() => {
                const { publishAudio, stream } = publisher.current
                publishAudio(!stream.hasAudio)
              }}
            />

            {isMobile ? (
              <CameraFlipToggle onClick={publisher.current?.cycleVideo} />
            ) : (
              <SettingsButton onClick={settingsModal.onOpen} />
            )}

            {isModerator && (
              <ParticipantsListButton onClick={participantsDrawer.onOpen} />
            )}
            <HangupButton onClick={endCallModal.onOpen} />
          </RoomControls>
        </Flex>
      </Slide>

      <ParticipantDrawer
        onClose={participantsDrawer.onClose}
        isOpen={participantsDrawer.isOpen}
        participantList={participantList}
        handleClick={removeParticipantFromCall}
      />

      <PermissionsModal
        isOpen={permissionsModal.isOpen}
        onClose={permissionsModal.onClose}
      />

      <EndCallModal
        isOpen={endCallModal.isOpen}
        onClose={endCallModal.onClose}
        onDisconnect={onDisconnect}
        isDoctor={isDoctor}
      />

      {publisher.current && settingsModal.isOpen && (
        <Settings
          isOpen={settingsModal.isOpen}
          onClose={settingsModal.onClose}
          publisher={publisher.current}
        />
      )}
    </Box>
  )
}
