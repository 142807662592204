import { Flex } from '@chakra-ui/react'
import { Footer } from 'components/presentational'
import Authentication from './components'

function SignIn() {
  return (
    <Flex justify="center" direction="column" height="100dvh">
      <Flex flex="1" bg="neutral.50">
        <Authentication />
      </Flex>
      <Footer backgroundColor={{ base: 'neutral.0', md: 'neutral.50' }} />
    </Flex>
  )
}

export default SignIn
