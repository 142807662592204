import { ReactComponent as TeleClinicLogoIcon } from 'assets/icons/TeleClinicLogo.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { LinkInlineIcon } from 'components/presentational/'
import { Icons } from 'components'
import { useLocation } from 'react-router-dom'

import './Header.scss'

const Header = ({ revokeAuthentication, isAuthenticated }) => {
  const { pathname } = useLocation()
  const isStandAlone = window.location === window.parent.location

  return (
    <div className="Header">
      <div className="Header__wrapper">
        <a href="/appointment">
          <TeleClinicLogoIcon className="logo" />
        </a>
        {isAuthenticated && (
          <div className="Header__user">
            {isStandAlone && (
              <LinkInlineIcon
                to={pathname === '/create' ? '/' : '/create'}
                data-cy="header_redirect_page_link"
              >
                <Icons.Clipboard />
                {pathname === '/create'
                  ? 'Meine Termin-Übersicht'
                  : 'Termin erstellen'}
              </LinkInlineIcon>
            )}

            <LinkInlineIcon
              onClick={revokeAuthentication}
              data-cy="logout_button"
              to="/signin"
            >
              <LogoutIcon />
              Abmelden
            </LinkInlineIcon>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
