/* eslint-disable no-underscore-dangle */
import { theme } from '@chakra-ui/theme'

const overrides = {
  fontWeight: 'button',
  borderRadius: 'button',
  minW: '265px',
  height: 'unset',
  lineHeight: 'none',
  py: '1.25em',
  px: '1.75em',
  _focus: { boxShadow: 'none' },
  _disabled: {
    opacity: 1,
    color: 'neutral.300',
    pointerEvents: 'none',
  },
}

const brandOverrides = {
  solid: {
    bg: 'surface.button.primary-default',
    color: 'content.button.primary',
    _active: {
      bg: 'surface.button.primary-pressed',
      color: 'content.button.primary',
    },
    _hover: {
      bg: 'surface.button.primary-hover',
      color: 'content.button.primary',
    },
    _disabled: {
      ...overrides._disabled,
      bg: 'surface.disabled',
      color: 'content.disabled',
    },
    _loading: {
      bg: 'surface.button.primary-default',
      color: 'content.button.primary',
      '& span.chakra-button__icon': {
        display: 'none',
      },
    },
  },
  link: {
    color: 'content.link.primary',
    _hover: { color: 'content.link.primary' },
    _active: { color: 'content.link.primary' },
  },
}

const Button = {
  variants: {
    solid: (props) => ({
      ...theme.defaults,
      ...overrides,
      _active: { bg: `${props.colorScheme}.800` },
      _disabled: {
        ...overrides._disabled,
        bg: 'neutral.50',
      },
      _loading: {
        bg: `${props.colorScheme}.500`,
        color: 'white',
        '& span.chakra-button__icon': {
          display: 'none',
        },
      },
      ...(props.colorScheme === 'brand' && brandOverrides.solid),
    }),
    outline: (props) => ({
      ...theme.defaults,
      ...overrides,
      borderWidth: '1.5px',
      color: `${props.colorScheme}.500`,
      py: 'calc(1.25em - 1.5px)',
    }),
    ghost: (props) => ({
      ...theme.defaults,
      color: `${props.colorScheme}.500`,
      ...overrides,
    }),
    link: (props) => {
      return {
        ...theme.defaults,
        _focus: { boxShadow: 'none' },
        textDecoration: 'underline',
        ...(props.colorScheme === 'brand' && brandOverrides.link),
        fontWeight: 'medium',
      }
    },
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}

export default Button
