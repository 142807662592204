import {
  Box,
  Grid,
  GridItem,
  Heading,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ReactComponent as TeleClinicLogoIcon } from 'assets/icons/TeleClinicLogo.svg'
import { Footer } from 'components/presentational'

export function Privacy() {
  return (
    <Grid
      flex="1"
      minH="100dvh"
      templateRows="1fr auto"
      templateAreas={`"main" "footer"`}
      bg="neutral.50"
    >
      <GridItem
        gridArea="main"
        placeSelf="center"
        p={{ base: 6, md: 16 }}
        maxW={{ base: '100dvw', md: 'container.md' }}
      >
        <Icon
          as={TeleClinicLogoIcon}
          mb="20"
          w="100px"
          h="auto"
          fill="primary.500"
        />
        <Text variant="body.xs.regular" mb="8">
          TC Version: 3.0
        </Text>
        <Heading variant="headline.lg.medium" mb="10">
          Datenschutzerklärung
          <br />
          Videosprechstundenmodul
        </Heading>

        <VStack spacing="10" align="stretch">
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              1. Inhalt dieser Hinweise
            </Heading>
            <Text>
              Diese Datenschutzerklärung gilt für Nutzer des Videosprechstunden
              Moduls. In diesem Abschnitt der Datenschutzerklärung informieren
              wir Sie im Detail über die Verarbeitung personenbezogener Daten im
              Rahmen unseres zertifizierten Videosprechstundenmoduls.
            </Text>
          </Box>
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              2. Über das Videosprechstundenmodul der TeleClinic
            </Heading>
            <Text>
              Über das Videosprechstundenmodul der TeleClinic wird eine
              gesicherte Verbindung zwischen Patient und Arzt ermöglicht.
            </Text>
          </Box>
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              3. Verantwortlicher und Datenschutzbeauftragter
            </Heading>
            <VStack spacing="4" align="stretch">
              <Text>
                3.1 Verantwortlich für die Verarbeitung der personenbezogenen
                Daten bei Nutzung der Videosprechstunde ist der behandelnde Arzt
                auf Grundlage des zwischen Patient und Arzt geschlossenen
                Behandlungsvertrages.
              </Text>
              <Text>
                Die TeleClinic verarbeitet diese Daten nur im Auftrag des Arztes
                nach Art. 28 DSGVO als Auftragsverarbeiter: <br />
                <br />
                TeleClinic GmbH
                <br />
                c/o Design Offices München
                <br />
                Brienner Straße 45a-d
                <br />
                80333 München
                <br />
                info@teleclinic.com
              </Text>
              <Text>
                Unseren Datenschutzbeauftragten erreichen sie unter der
                folgenden Kontaktadresse:
                <br />
                <br />
                TeleClinic GmbH
                <br />
                c/o Design Offices München
                <br />
                Brienner Straße 45a-d
                <br />
                80333 München
                <br />
                datenschutz@teleclinic.com
              </Text>
            </VStack>
          </Box>
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              4. Allgemeines zu den Datenverarbeitungen
            </Heading>
            <Text>
              Für alle nachstehend dargestellten Verarbeitungen gilt, soweit
              nichts anderes angegeben wird:
            </Text>
            <VStack spacing="8" align="stretch" mt="8">
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  4.1. Hosting bei externen Dienstleistern
                </Heading>
                <Text>
                  Die Datenverarbeitung erfolgt in weitem Umfang unter
                  Einschaltung sog. Hostingdienstleister, die uns Speicherplatz
                  und Verarbeitungskapazitäten in ihren Rechenzentren zur
                  Verfügung stellen und nach Weisung der TeleClinic auch
                  personenbezogene Daten im Auftrag verarbeiten. Es kann bei
                  allen nachstehend genannten Funktionalitäten geschehen, dass
                  personenbezogene Daten an Hostingdienstleister übermittelt
                  werden. Diese Dienstleister verarbeiten Daten ausschließlich
                  in der EU.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  4.2. Keine Verarbeitung von Videogesprächen
                </Heading>
                <Text>
                  TeleClinic verarbeitet keine Daten, die im Rahmen der
                  Videogespräche ausgetauscht werden. Die Videosprechstunde
                  basiert auf einer Ende-zu-Ende-verschlüsselten Verbindung
                  zwischen den Endgeräten der Gesprächspartner (je nach Anzahl
                  der Teilnehmer entweder Peer-to-Peer oder mithilfe eines
                  Relay- oder Media-Servers), auf die TeleClinic technisch
                  keinen Zugriff hat. Die Gespräche werden von TeleClinic gemäß
                  der Anlage 31b zum BMV-Ärzte nicht erfasst, verarbeitet oder
                  gespeichert. Das Videogespräch ist vertraulich.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  4.3. Rechtsgrundlagen
                </Heading>
                <Text>
                  Die Rechtsgrundlage zur Verarbeitung Ihrer personenbezogenen
                  Daten wird in der Regel vom Verantwortlichen der jeweiligen
                  Datenverarbeitung, also dem Arzt bestimmt und diese ergeben
                  sich regelmäßig aus Art. 6 Abs. 1 lit. a-f DSGVO und bei
                  Gesundheitsdaten aus Art. 9 Abs. 2 lit. a-j DSGVO. Der Arzt
                  hat mit der TeleClinic für die Nutzung der Videosprechstunden
                  einen sogenannten Auftragsverarbeitungsvertrag gem. Art. 28
                  DSGVO abgeschlossen. Die TeleClinic ist hierbei
                  Auftragsverarbeiter und verarbeitet die personenbezogenen
                  Daten des Verantwortlichen entsprechend im Auftrag und nutzt
                  somit dieselbe Rechtsgrundlage wie der verantwortliche Arzt
                  für die Auftragsverarbeitung. Folgende personenbezogenen Daten
                  werden im Rahmen des Videosprechstundenmoduls von TeleClinic
                  im Auftrag des behandelnden Arztes verarbeitet:
                </Text>
                <Grid
                  templateColumns="repeat(6, 1fr)"
                  style={{ hyphens: 'auto' }}
                  gap={4}
                  mt="8"
                  fontSize="xs"
                  overflow="scroll"
                >
                  <GridItem fontWeight="medium">Datenkategorie</GridItem>
                  <GridItem fontWeight="medium">Zweckbestimmung</GridItem>
                  <GridItem fontWeight="medium">Rechtsgrundlage</GridItem>
                  <GridItem fontWeight="medium">
                    Ggf. berechtigtes Interesse
                  </GridItem>
                  <GridItem fontWeight="medium"> Dienstleister</GridItem>
                  <GridItem fontWeight="medium">Land</GridItem>

                  <GridItem>E-Mail-Adresse</GridItem>
                  <GridItem>
                    Versenden der Termindetails für das Videogespräch mit dem
                    Arzt an den Patienten
                  </GridItem>
                  <GridItem>Art. 6 Abs.1 lit. b DSGVO</GridItem>
                  <GridItem />
                  <GridItem>Amazon Web Services (AWS)</GridItem>
                  <GridItem>Deutschland</GridItem>

                  <GridItem>TAN Code</GridItem>
                  <GridItem>
                    Zuordnung des Patienten zum jeweiligen Arzt
                  </GridItem>
                  <GridItem>Art. 9 Abs. 2 lit. h DSGVO</GridItem>
                  <GridItem />
                  <GridItem>Amazon Web Services (AWS)</GridItem>
                  <GridItem>Deutschland</GridItem>

                  <GridItem>Vorname und Nachname</GridItem>
                  <GridItem>
                    Identifizierung, Kontrolle der Berechtigung zum Abruf des
                    Angebots
                  </GridItem>
                  <GridItem>Art. 9 Abs. 2 lit. h DSGVO</GridItem>
                  <GridItem />
                  <GridItem>Amazon Web Services (AWS)</GridItem>
                  <GridItem>Deutschland</GridItem>

                  <GridItem>Anmeldedaten, Zugriffsdaten</GridItem>
                  <GridItem>
                    Verbindungsaufbau, Darstellung der Inhalte des Service,
                    Entdeckung von Angriffen auf unsere Seite anhand
                    ungewöhnlicher Aktivitäten, Fehlerdiagnose, Erbringung
                    unserer Services
                  </GridItem>
                  <GridItem>Art. 6 Abs. 1 lit. f DSGVO</GridItem>
                  <GridItem>
                    Sicherheit von Daten und Geschäftsprozessen, Verhinderung
                    von Missbrauch
                  </GridItem>
                  <GridItem>Amazon Web Services (AWS)</GridItem>
                  <GridItem>Deutschland</GridItem>
                </Grid>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  4.4 Kriterien für die Speicherdauer
                </Heading>
                <Text>
                  Soweit keine konkrete Speicherfrist bzw. Kriterien innerhalb
                  dieser Datenschutzerklärung für die Fristbestimmung angegeben
                  sind, gilt folgendes: Wir bemessen die Speicherdauer für die
                  Daten anhand der konkreten Zwecke, zu denen wir die Daten
                  verwenden und anhand eventueller Weisungen, die uns der
                  Verantwortliche als Auftragsverarbeiter erteilt hat oder im
                  Auftragsverarbeitungsvertrag vereinbart wurde. Darüber hinaus
                  unterliegen wir bzw. die Ärzte gesetzlichen Aufbewahrungs- und
                  Dokumentationspflichten, die sich insbesondere aus dem
                  Handelsgesetzbuch (HGB), der Abgabenordnung (AO), der
                  ärztlichen Berufsordnung und dem Bundesmantelvertrag-Ärzte
                  ergeben und häufig sechs oder zehn Jahre betragen. Schließlich
                  beurteilt sich die Speicherdauer auch nach den gesetzlichen
                  Verjährungsfristen, die zum Beispiel nach den §§ 195 ff. des
                  Bürgerlichen Gesetzbuches (BGB) in der Regel drei Jahre (ab
                  Ende des Kalenderjahres) betragen. Daten gemäß Anlage 31b zum
                  BMV-Ärzte werden nach spätestens 3 Monaten gelöscht.
                </Text>
              </Box>
            </VStack>
          </Box>
          <Box>
            <Heading variant="headline.md.medium" mb="4">
              5. Betroffenenrechte
            </Heading>
            <Text>
              Diese Rechte stehen dem Betroffenen (Nutzer der Videosprechstunde)
              unter den Voraussetzungen der jeweiligen datenschutzrechtlichen
              Bestimmungen zu. Durch die nachfolgende Darstellung werden keine
              weitergehenden Rechte eingeräumt. Die TeleClinic kann als
              Auftragsverarbeiter auch unten angeführte Rechte im Auftrag des
              Verantwortlichen erfüllen.
            </Text>
            <VStack spacing="8" align="stretch" mt="8">
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.1. Auskunft, Art. 15 DSGVO
                </Heading>
                <Text>
                  Der Nutzer hat das Recht, von TeleClinic eine Bestätigung
                  darüber zu verlangen, ob über ihn betreffende personenbezogene
                  Daten verarbeitet werden; ist dies der Fall, so hat er ein
                  Recht auf Auskunft über diese personenbezogenen Daten und auf
                  die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.2 Berichtigung, Art. 16 DSGVO
                </Heading>
                <Text>
                  Der Nutzer hat das Recht, von TeleClinic unverzüglich die
                  Berichtigung ihn betreffender unrichtiger personenbezogener
                  Daten und ggf. die Vervollständigung unvollständiger
                  personenbezogener Daten zu verlangen, Art. 16 DSGVO.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.3 Löschen („Recht auf Vergessenwerden“), Art. 17 DSGVO
                </Heading>
                <Text>
                  Der Nutzer hat das Recht, von TeleClinic zu verlangen, dass
                  ihn betreffende personenbezogene Daten unverzüglich gelöscht
                  werden, sofern einer der in Art. 17 DSGVO im Einzelnen
                  aufgeführten Gründe zutrifft, z. B. wenn die Daten für die
                  verfolgten Zwecke nicht mehr benötigt werden. Dieses Recht
                  kann eingeschränkt sein, wenn TeleClinic gleichwohl aufgrund
                  gesetzlicher Aufbewahrungsfristen eine Löschung nicht
                  vornehmen kann. In diesem Fall sperren wir die
                  personenbezogenen Daten und informieren den Nutzer hierüber.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.4. Einschränkung der Verarbeitung, Art. 18 DSGVO
                </Heading>
                <Text>
                  Der Nutzer hat das Recht, von TeleClinic die Einschränkung der
                  Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO
                  aufgeführten Voraussetzungen gegeben ist, z. B. wenn er
                  Widerspruch gegen die Verarbeitung eingelegt hat, für die
                  Dauer der Prüfung durch uns.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.5. Datenübertragbarkeit, Art. 20 DSGVO
                </Heading>
                <Text>
                  Der Nutzer hat das Recht, unter bestimmten Voraussetzungen ihn
                  betreffende Daten, die er TeleClinic bzw. dem behandelnden
                  Arzt bereitgestellt hat, in einem strukturierten, gängigen und
                  maschinenlesbaren Format zu erhalten, zu übermitteln und -
                  soweit technisch machbar - übermitteln zu lassen. Dieses Recht
                  steht dem Nutzer nur dann zu, wenn TeleClinic bzw. der
                  behandelnde Arzt personenbezogene Daten aufgrund seiner
                  Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO oder nach Art. 9
                  Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Artikel 6
                  Abs. 1 lit. b DSGVO verarbeitet oder ein automatisiertes
                  Verfahren zur Verarbeitung nutzt.
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.6. Beschwerde, Art. 77 DSGVO
                </Heading>
                <Text>
                  Der Nutzer hat unabhängig von anderweitigen
                  verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfen das
                  Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn er der
                  Ansicht ist, dass die Verarbeitung der ihn betreffenden
                  personenbezogenen Daten durch TeleClinic bzw. den behandelnden
                  Arzt gegen die DSGVO verstößt. Der Nutzer kann dieses Recht
                  bei einer Aufsichtsbehörde in dem Mitgliedstaat seines
                  Aufenthaltsorts, seines Arbeitsplatzes oder des Orts des
                  mutmaßlichen Verstoßes geltend machen. Die Kontaktdaten der
                  Aufsichtsbehörden in Deutschland finden Sie unter{' '}
                  <Link href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                  </Link>
                </Text>
                <Text mt="4">
                  Adress- und Kontaktdaten der für TeleClinic zuständigen
                  Aufsichtsbehörde:
                  <br />
                  Bayerisches Landesamt für Datenschutzaufsicht (BayLDA),
                  <br />
                  Promenade 18, 91522 Ansbach
                  <br />
                  Telefon: +49 (0) 981 180093-0
                  <br />
                  Telefax: +49 (0) 981 180093-800
                  <br />
                  Online- Beschwerde:
                  https://www.lda.bayern.de/de/beschwerde.html
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.7. Widerruf (von Einwilligungen), Art. 7 Abs. 3 DSGVO
                </Heading>
                <Text>
                  Wenn der Nutzer TeleClinic eine Datenschutz-Einwilligung nach
                  Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO
                  erteilt hat, hat er das Recht, diese jederzeit mit Wirkung für
                  die Zukunft zu widerrufen. Dies gilt auch für
                  Datenschutz-Einwilligungen, die uns vor Geltung der DSGVO
                  erteilt wurden. Die Datenverarbeitung bis zum Zeitpunkt des
                  Widerrufes bleibt rechtmäßig. Aus der Nichteinwilligung oder
                  einem späteren Widerruf der Einwilligung entstehen Ihnen keine
                  Nachteile
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.8. Widerspruch, Art. 21 DSGVO
                </Heading>
                <Text variant="body.sm.regular">
                  WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1
                  LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT,
                  AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
                  GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
                  WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE
                  BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
                  RECHTSGRUNDLAGE, AUF DER EINE VERARBEITUNG BERUHT, ENTNEHMEN
                  SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
                  EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN
                  NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE
                  SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE
                  INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                  VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
                  VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21
                  ABS. 1 DSGVO).
                </Text>
              </Box>
              <Box>
                <Heading variant="headline.xs.medium" mb="3">
                  5.9 Automatisierte Entscheidungsfindung / Profiling
                </Heading>
                <Text>
                  Die TeleClinic setzt keine automatisierten
                  Entscheidungsfindungen einschließlich Profiling im Sinne des
                  Artikel 22 DSGVO ein.
                </Text>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </GridItem>
      <GridItem gridArea="footer">
        <Footer />
      </GridItem>
    </Grid>
  )
}
