import {
  Box,
  Flex,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react'
import { Pagination } from 'components'
import { NetworkContext } from 'components/contextProvider'
import { EmptyState, Spacer } from 'components/presentational'
import { STATUS_INITIALIZED, STATUS_IN_PROGRESS } from 'constants'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { appointmentListEntity } from 'store/entities'
import { FILTER } from '../constants'
import AppointmentTeaser from './AppointmentTeaser'
import { orderByDateTime } from './utils'

const Appointments = ({ setLoading, filter, page, searchQuery }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const { getAppointments } = useContext(NetworkContext)
  const pageSize = 7
  const [appointments, setAppointments] = useState({
    data: null,
    count: 0,
  })

  const fetchData = async () => {
    setLoading(true)
    if (filter === FILTER.UPCOMING) {
      try {
        const res = await getAppointments({
          upcoming: true,
          pageSize,
          page,
        })
        const orderedAppointments = orderByDateTime(
          appointmentListEntity(res.items),
        )
        setAppointments({
          data: orderedAppointments,
          count: Math.ceil(res.count / pageSize),
        })
        setLoading(false)
      } catch {
        setLoading(false)
      }
      return
    }

    try {
      const result = await getAppointments({
        upcoming: false,
        pageSize,
        page,
        ascendingOrdering: false,
      })
      setAppointments({
        data: appointmentListEntity(result.items),
        count: Math.ceil(result.count / pageSize),
      })
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const onPageChange = ({ selected }) => {
    navigate(`?filter=${filter}&page=${selected + 1}`)
  }

  const onSectionChange = (tab) => {
    navigate(
      `/appointment?filter=${
        tab === 0 ? FILTER.UPCOMING : FILTER.HISTORY
      }&page=${1}`,
      { replace: true },
    )
  }

  const appointmentOnClick = (id, status) => {
    if (status === STATUS_INITIALIZED || status === STATUS_IN_PROGRESS) {
      return navigate(`/waiting-room/${id}${searchQuery}`)
    }
    return toast({
      description: 'Sie können dem Gespräch nicht mehr beitreten',
      variant: 'subtle',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchData()
    }
    return () => {
      mounted = false
    }
  }, [filter, page])

  return (
    <Flex direction="column" align="center" bg="neutral.0" flex="1" p="25px">
      <Tabs onChange={onSectionChange} w="100%" variant="unstyled">
        <Spacer space={50} />
        <TabList color="neutral.400">
          <Tab fontWeight="medium" _selected={{ color: 'primary.600' }}>
            Anstehende
          </Tab>
          <Tab fontWeight="medium" _selected={{ color: 'primary.600' }}>
            Gesprächshistorie
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="3px"
          bg="primary.600"
          borderRadius="1px"
        />

        <TabPanels>
          <Box width="100%" height="100%" minH="700px">
            <Spacer space={50} />
            {appointments.data == null ? (
              <>
                <Skeleton height="80px" />
                <Spacer space={5} />
                <Skeleton height="80px" />
                <Spacer space={5} />
                <Skeleton height="80px" />
              </>
            ) : (
              <>
                {appointments.data.length > 0 && (
                  <>
                    {appointments.data.map(
                      ({ id, name, caseId, status, date }) => (
                        <Fragment key={id}>
                          <AppointmentTeaser
                            id={id}
                            dateTime={date}
                            name={name}
                            caseId={caseId}
                            onClick={() => appointmentOnClick(id, status)}
                            status={status}
                            filter={filter}
                          />
                        </Fragment>
                      ),
                    )}
                  </>
                )}
                {appointments.data.length === 0 &&
                  (filter === FILTER.UPCOMING ? (
                    <EmptyState text="Sie haben keine anstehenden Termine" />
                  ) : (
                    <EmptyState text="Sie haben keine alten Termine" />
                  ))}
              </>
            )}
          </Box>

          {appointments.data !== null && appointments.count > 1 && (
            <Flex minW="33%" maxW="50%">
              <Pagination
                pageCount={appointments.count}
                onPageChange={onPageChange}
                forcePage={Number(page - 1)}
              />
            </Flex>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default Appointments
