import moment from 'moment'
import * as Yup from 'yup'

export default Yup.object({
  name: Yup.string()
    .min(1, 'Bitte geben Sie einen Vornamen an')
    .required('Bitte geben Sie hier den Vornamen des Patienten an')
    .matches(/[^\d]$/, 'Bitte keine Zahlen im Patientennamen'),
  surname: Yup.string()
    .min(1, 'Bitte geben Sie einen Nachnamen an')
    .required('Bitte geben Sie hier den Nachnamen des Patienten an')
    .matches(/[^\d]$/, 'Bitte keine Zahlen im Patientennamen'),
  email: Yup.string()
    .email('Ungültige E-Mail-Adresse')
    .required('Bitte geben Sie hier die E-Mail-Adresse des Patienten an'),
  date: Yup.date()
    .required('Bitte geben Sie das Datum des Termins an')
    .test({
      test: (value) => {
        if (moment(value).isBefore(moment().subtract('1', 'days'))) {
          return false
        }
        return value
      },
      message: 'Bitte wählen Sie ein Datum in der Zukunft aus',
    }),
  time: Yup.date()
    .transform((_, originalValue) => moment(originalValue, 'HH:mm').toDate())
    .required('Fehlende Uhrzeit')
    .when('date', (date, schema) => {
      const isDateToday = moment(date).isSame(moment(), 'day')
      const currentTime = moment().format('HH:mm')
      if (isDateToday) {
        return schema.min(
          currentTime,
          'Bitte wählen Sie eine Uhrzeit in der Zukunft aus',
        )
      }
      return schema
    }),
  dataPrivacy: Yup.boolean()
    .oneOf([true], 'Bitte bestätigen')
    .required('Bitte bestätigen'),
})
