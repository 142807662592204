import { FormControl, FormLabel, Select, VStack } from '@chakra-ui/react'
import OT from '@opentok/client'
import { ModalDrawer } from 'components/presentational'
import { useEffect, useState } from 'react'

export function Settings({ isOpen, onClose, publisher }) {
  const [devices, setDevices] = useState()
  const [current, setCurrent] = useState()

  const onVideoSourceSelected = async (e) => {
    await publisher.setVideoSource(e.target.value)
  }

  const onAudioSourceSelected = async (e) => {
    await publisher.setAudioSource(e.target.value)
  }

  const onAudioOutputSelected = async (e) => {
    await OT.setAudioOutputDevice(e.target.value)
  }

  const onInit = async () => {
    const speakers = await OT.getAudioOutputDevices()
    const activeSpeaker = await OT.getActiveAudioOutputDevice()

    OT.getDevices((err, deviceList) => {
      if (err) return

      const cameras = deviceList.filter((d) => d.kind === 'videoInput')
      const activeCamera = publisher.getVideoSource()

      const microphones = deviceList.filter((d) => d.kind === 'audioInput')
      const activeMic = microphones.find(
        (d) => d.label === publisher.getAudioSource().label,
      )

      setDevices({ cameras, microphones, speakers })
      setCurrent({
        camera: activeCamera.deviceId,
        microphone: activeMic.deviceId,
        speaker: activeSpeaker.deviceId,
      })
    })
  }

  useEffect(() => {
    onInit()
  }, [])

  if (!devices || !current) return null

  return (
    <ModalDrawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <VStack w="full" py="8" px="6" spacing={4}>
        <FormControl>
          <FormLabel>Camera</FormLabel>
          <Select
            focusBorderColor="primary.500"
            onChange={onVideoSourceSelected}
            defaultValue={current.camera}
          >
            {devices.cameras.map((camera) => (
              <option key={camera.deviceId} value={camera.deviceId}>
                {camera.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Microphone</FormLabel>
          <Select
            onChange={onAudioSourceSelected}
            defaultValue={current.microphone}
          >
            {devices.microphones.map((mic) => (
              <option key={mic.deviceId} value={mic.deviceId}>
                {mic.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Speakers</FormLabel>
          <Select
            colorScheme="primary"
            onChange={onAudioOutputSelected}
            defaultValue={current.speaker}
          >
            {devices.speakers.map((speaker) => (
              <option key={speaker.deviceId} value={speaker.deviceId}>
                {speaker.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </VStack>
    </ModalDrawer>
  )
}
