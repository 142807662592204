import { ReactComponent as Account } from './Account.svg'
import { ReactComponent as AlertCircle } from './AlertCircle.svg'
import { ReactComponent as Apple } from './Apple.svg'
import { ReactComponent as ArrowRight } from './ArrowRight.svg'
import { ReactComponent as Calendar } from './Calendar.svg'
import { ReactComponent as CalendarDetailed } from './CalendarDetailed.svg'
import { ReactComponent as CallPatient } from './CallPatient.svg'
import { ReactComponent as Camera } from './Camera.svg'
import { ReactComponent as CheckCircle } from './CheckCircle.svg'
import { ReactComponent as Checkmark } from './Checkmark.svg'
import { ReactComponent as ChevronLeft } from './chevron-left.svg'
import { ReactComponent as ChevronRight } from './chevron-right.svg'
import { ReactComponent as ChevronDown } from './ChevronDown.svg'
import { ReactComponent as ChevronDown1 } from './ChevronDown1.svg'
import { ReactComponent as ChevronUp } from './ChevronUp1.svg'
import { ReactComponent as Clipboard } from './Clipboard.svg'
import { ReactComponent as Clock } from './Clock.svg'
import { ReactComponent as Close } from './Close.svg'
import { ReactComponent as CreditCard } from './CreditCard.svg'
import { ReactComponent as Document } from './Document.svg'
import { ReactComponent as DollarSign } from './DollarSign.svg'
import { ReactComponent as Dot } from './Dot.svg'
import { ReactComponent as Download } from './Download.svg'
import { ReactComponent as EuroSign } from './EuroSign.svg'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as Layers } from './Layers.svg'
import { ReactComponent as Loading } from './Loading.svg'
import { ReactComponent as Lock } from './Lock.svg'
import { ReactComponent as Logout } from './Logout.svg'
import { ReactComponent as LongArrowLeft } from './LongArrowLeft.svg'
import { ReactComponent as LongArrowRight } from './LongArrowRight.svg'
import { ReactComponent as Menu } from './Menu.svg'
import { ReactComponent as Message } from './Message.svg'
import { ReactComponent as Paperclip } from './Paperclip.svg'
import { ReactComponent as Pen } from './Pen.svg'
import { ReactComponent as PhoneCall } from './PhoneCall.svg'
import { ReactComponent as PhoneDisconnect } from './PhoneDisconnect.svg'
import { ReactComponent as Settings } from './Settings.svg'
import { ReactComponent as SimpleClock } from './SimpleClock.svg'
import { ReactComponent as StarEmpty } from './StarEmpty.svg'
import { ReactComponent as StarFull } from './StarFull.svg'
import { ReactComponent as StarHalf } from './StarHalf.svg'
import { ReactComponent as Talk } from './Talk.svg'
import { ReactComponent as TeleClinicLogo } from './TeleClinicLogo.svg'
import { ReactComponent as TeleclinicPlusRound } from './TeleclinicPlusRound.svg'
import { ReactComponent as Tooltip } from './Tooltip.svg'
import { ReactComponent as Trash } from './Trash.svg'
import { ReactComponent as Users } from './Users.svg'
import { ReactComponent as VerticalDots } from './VerticalDots.svg'
import { ReactComponent as VideoLogo } from './VideoLogo.svg'
import { ReactComponent as Warning } from './Warning.svg'
import { ReactComponent as Windows } from './Windows.svg'

export default {
  TeleClinicLogo,
  Checkmark,
  CallPatient,
  Settings,
  Logout,
  VideoLogo,
  Eye,
  VerticalDots,
  Warning,
  Trash,
  DollarSign,
  EuroSign,
  Document,
  CreditCard,
  Menu,
  Layers,
  Calendar,
  Close,
  Camera,
  Loading,
  Clipboard,
  LongArrowRight,
  LongArrowLeft,
  ArrowRight,
  ChevronDown,
  ChevronDown1,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  Paperclip,
  CheckCircle,
  StarEmpty,
  StarFull,
  StarHalf,
  Tooltip,
  AlertCircle,
  Dot,
  Download,
  Apple,
  Windows,
  Lock,
  Clock,
  SimpleClock,
  Talk,
  Message,
  Account,
  TeleclinicPlusRound,
  Users,
  Pen,
  PhoneCall,
  PhoneDisconnect,
  CalendarDetailed,
}
