export const REQUEST_HEADER_FORM = 'multipart/form-data'
export const REQUEST_HEADER_JSON = 'application/json'

class ResponseError extends Error {
  constructor(statusCode, responseBody) {
    // set the message field of the error instance to be the payload
    super(JSON.stringify(responseBody))
    this.status = statusCode
  }
}

async function requestModule({ url, method, header, accessToken, payload }) {
  const headers = new Headers()
  let body

  // build headers
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`)
  }

  // build body
  if (header === REQUEST_HEADER_FORM) {
    body = new FormData()
    Object.keys(payload).forEach((formDataKey) =>
      body.append(formDataKey, payload[formDataKey]),
    )
  }

  if (header === REQUEST_HEADER_JSON) {
    headers.append('Content-Type', header)
    body = JSON.stringify(payload)
  }

  // handle response
  const response = await fetch(url, {
    method,
    body,
    headers,
  })

  const getContentType = response.headers.get('Content-Type')

  if (response.ok) {
    if (
      getContentType !== null &&
      getContentType.includes('application/json')
    ) {
      if (response.status === 204) {
        return null
      }
      return response.json()
    }

    if (
      getContentType !== null &&
      getContentType.includes('text/csv; charset=utf-8')
    ) {
      const text = await response.text()
      const file = new Blob([text], { type: 'text/csv' })
      const fileURL = URL.createObjectURL(file)
      window.location.href = fileURL
      return null
    }
    return response
  }

  const responseBody = await response.json()
  return {
    error: true,
    payload: new ResponseError(response.status, responseBody),
  }
}

export default requestModule
