import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Icons } from 'components'
import { NetworkContext } from 'components/contextProvider'
import { Footer } from 'components/presentational'
import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ERRORS } from './constants'

export default function Lobby() {
  const toast = useToast()
  const navigate = useNavigate()
  const [isReady, setIsReady] = useState(false)
  const { getSessionCredentials } = useContext(NetworkContext)
  const params = new URLSearchParams(window.location.search)

  const onSubmit = async (values, actions) => {
    try {
      const { name, tan } = values
      const res = await getSessionCredentials({ tan })
      navigate('/room', {
        state: {
          name,
          token: res.token,
          sessionId: res.session_id,
          apiKey: res.application_id,
        },
      })
    } catch (e) {
      const error = JSON.parse(e.message)
      const message = ERRORS[error.code] || ERRORS.default
      toast({
        description: message,
        variant: 'subtle',
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
      const { setSubmitting } = actions
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    onSubmit,
    initialValues: {
      name: params.get('name') || '',
      tan: params.get('tan_code') || '',
      consentProcessing: false,
    },
  })

  useEffect(() => {
    const { sessionStorage } = window
    if (params.get('redirect')) {
      const redirect = JSON.stringify(params.get('redirect'))
      sessionStorage.setItem('redirect', redirect)
    }

    if (params.get('partner_id')) {
      const partnerId = JSON.stringify(params.get('partner_id'))
      sessionStorage.setItem('partnerId', partnerId)
    }

    if (params.get('submit')) {
      formik.handleSubmit()
      return
    }

    setIsReady(true)
  }, [])

  if (!isReady)
    return (
      <Center h="100dvh">
        <Spinner color="primary.500" size="xl" />
      </Center>
    )

  return (
    <Grid
      flex="1"
      minH="100dvh"
      templateRows="1fr auto"
      templateAreas={`"main" "footer"`}
      bg="neutral.50"
    >
      <GridItem gridArea="main" placeSelf="center" p={{ base: 0, md: 16 }}>
        <Box
          bg="neutral.0"
          overflow="hidden"
          maxW="container.sm"
          borderRadius={{ base: 0, md: 'xl' }}
        >
          <Flex bg="primary.500" direction="column" align="center" p="6">
            <Icon as={Icons.VideoLogo} boxSize="60px" fill="neutral.0" />
            <Text fontSize="xl" color="neutral.0">
              <Text as="span" fontWeight="500">
                tele
              </Text>
              clinic.video
            </Text>
          </Flex>
          <Flex
            direction="column"
            px={{ base: 6, md: 20 }}
            py={{ base: 8, md: 20 }}
          >
            <Heading
              variant={{ base: 'headline.sm.medium', md: 'headline.md.medium' }}
            >
              Videosprechstunde beitreten
            </Heading>
            <Text mt="2" mb="8">
              Bitte geben Sie Ihren Namen und die Einwahldaten (TAN) für die
              Videosprechstunde ein. Die Einladung haben Sie per E-Mail von der
              Arztpraxis erhalten.
            </Text>

            <form onSubmit={formik.handleSubmit}>
              <VStack spacing="6">
                <FormControl>
                  <FormLabel>Vorname und Nachname</FormLabel>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Bitte angeben"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Einwahldaten (TAN)</FormLabel>
                  <Input
                    name="tan"
                    type="text"
                    placeholder="Bitte angeben"
                    value={formik.values.tan}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <VStack mt="3" mb="8" align="stretch" spacing="4">
                  <Checkbox
                    name="consentProcessing"
                    onChange={formik.handleChange}
                    isChecked={formik.values.consentProcessing}
                  >
                    <Text variant="body.sm.regular" ml="3">
                      Hiermit willige ich in die Verarbeitung meiner Daten
                      [Vorname, Nachname, E-Mail-Adresse, IP-Adresse, Anmelde-
                      und Zugriffsdaten] zum Zweck der Bereitstellung der
                      Videosprechstunde durch die TeleClinic GmbH ein. Die Daten
                      werden maximal 90 Tage gespeichert. Meine Einwilligung
                      kann ich jederzeit widerrufen. TeleClinic kann die Inhalte
                      der Videosprechstunde zu keinem Zeitpunkt einsehen.
                      <br />
                      <br />
                      Weitergehende Informationen finden Sie in den{' '}
                      <Link
                        textDecoration="underline"
                        href="https://www.teleclinic.com/wp-content/uploads/Datenschutz-Einwilligung_Patient_Videomodul_Neuzertifizierung2024-2.pdf"
                      >
                        {' '}
                        Hinweisen zur Einwilligungserklärung
                      </Link>{' '}
                      und unserer{' '}
                      <Link textDecoration="underline" href="/privacy">
                        Datenschutzerklärung
                      </Link>
                      .
                    </Text>
                  </Checkbox>
                </VStack>
                <Button
                  type="submit"
                  isDisabled={
                    formik.isSubmitting ||
                    !(
                      formik.values.name &&
                      formik.values.tan &&
                      formik.values.consentProcessing
                    )
                  }
                >
                  Videosprechstunde beitreten
                </Button>
              </VStack>
            </form>
          </Flex>
        </Box>
      </GridItem>

      <GridItem gridArea="footer">
        <Footer />
      </GridItem>
    </Grid>
  )
}
