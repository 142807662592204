import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react'
import { NetworkContext } from 'components/contextProvider'
import { Spacer } from 'components/presentational/'
import Page from 'components/view/Page'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import moment from 'moment'
import 'moment/locale/de'
import { useContext, useState } from 'react'
import appointmentSchema from 'services/validation/schemas/appointmentSchema'
import { createdAppointmentEntity } from 'store/entities'
import Confirmation from './components/Confirmation'

const CreateAppointment = () => {
  const DEFAULT_STATE = null
  const toast = useToast()
  const { createAppointment } = useContext(NetworkContext)
  const [appointmentToken, setAppointmentToken] = useState(DEFAULT_STATE)
  const [appointmentDate, setAppointmentDate] = useState(DEFAULT_STATE)
  const [isLoading, setIsLoading] = useState(false)
  const minDate = moment().toISOString().split('T')[0]
  const initialValues = {
    name: '',
    surname: '',
    email: '',
    date: '',
    time: '',
    dataPrivacy: false,
  }

  const errorMessage = (inputName) => (
    <ErrorMessage
      name={inputName}
      render={(msg) => (
        <Text color="semantics.error" fontSize="xs">
          {msg}
        </Text>
      )}
    />
  )

  const submitAppointment = async ({ date, time, name, surname, email }) => {
    setIsLoading(true)
    try {
      const formattedDate = moment(
        `${date} ${time}`,
        'YYYY-MM-DDTHH:mm',
      ).toISOString()

      const res = createdAppointmentEntity(
        await createAppointment({
          date: formattedDate,
          email,
          name: `${name} ${surname}`,
        }),
      )
      setAppointmentToken(res.token)
      setAppointmentDate(formattedDate)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    } catch {
      toast({
        title: 'Ein Fehler ist aufgetreten',
        description: 'Bitte versuchen Sie es später erneut.',
        variant: 'subtle',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      setIsLoading(false)
    }
  }
  return (
    <Page>
      {() => (
        <Flex flex="1" bg="neutral.0">
          <Flex direction="column" padding="60px 100px">
            {appointmentToken ? (
              <Confirmation
                appointmentToken={appointmentToken}
                appointmentDate={appointmentDate}
              />
            ) : (
              <>
                <Box color="neutral.800">
                  <Heading variant="headline.lg.medium">
                    Termin erstellen
                  </Heading>
                  <Text>
                    Bitte geben Sie die Daten des Patienten hier ein und legen
                    Sie einen Termin für die Videosprechstunde fest.
                  </Text>
                  <Text>
                    Ihr Patient bekommt im Anschluss die Zugangsdaten zur
                    Videosprechstunde per E-Mail zugesendet.
                  </Text>
                </Box>
                <Box>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={appointmentSchema}
                    onSubmit={submitAppointment}
                  >
                    {({
                      isSubmitting,
                      handleBlur,
                      values,
                      errors,
                      dirty,
                      handleChange,
                    }) => (
                      <Form>
                        <Flex direction="column" maxW="500px">
                          <Flex direction="column" mt="40px">
                            <FormControl>
                              <FormLabel>Vorname des Patienten</FormLabel>
                              <Field
                                type="text"
                                name="name"
                                placeholder="Bitte angeben"
                                onBlur={handleBlur}
                                as={Input}
                              />
                              {errorMessage('name')}
                            </FormControl>
                          </Flex>
                          <Flex direction="column" mt="30px">
                            <FormControl>
                              <FormLabel>Nachname des Patienten</FormLabel>
                              <Field
                                type="text"
                                name="surname"
                                placeholder="Bitte angeben"
                                onBlur={handleBlur}
                                as={Input}
                              />
                              {errorMessage('surname')}
                            </FormControl>
                          </Flex>
                          <Flex direction="column" mt="30px">
                            <FormControl>
                              <FormLabel> E-Mail des Patienten</FormLabel>
                              <Field
                                type="email"
                                name="email"
                                placeholder="Bitte angeben"
                                onBlur={handleBlur}
                                as={Input}
                              />
                              {errorMessage('email')}
                            </FormControl>
                          </Flex>
                        </Flex>

                        <Heading mt="50px" variant="headline.sm.medium">
                          Wann soll der Termin stattfinden?
                        </Heading>
                        <Flex mt="10px" mb="10px">
                          <Flex direction="column">
                            <Field
                              type="date"
                              name="date"
                              mask="true"
                              onBlur={handleBlur}
                              as={Input}
                              p="15px"
                              min={minDate}
                            />
                          </Flex>
                          <Flex direction="column">
                            <Field
                              type="time"
                              name="time"
                              onBlur={handleBlur}
                              as={Input}
                              step="300"
                              ml="10px"
                              p="13px"
                            />
                          </Flex>
                        </Flex>
                        {errorMessage('date')}
                        {errorMessage('time')}
                        <Spacer space={30} />
                        <Field
                          name="dataPrivacy"
                          type="checkbox"
                          onChange={handleChange}
                          as={Checkbox}
                          isChecked={values.dataPrivacy}
                        >
                          <Box paddingBottom="10" data-cy="checkbox">
                            Patient hat der Terminstellung, der Termineinladung
                            per Email und den damit verbunden
                            Datenverarbeitungen zugestimmt.
                          </Box>
                        </Field>
                        <Button
                          mt={7}
                          type="submit"
                          isDisabled={Object.keys(errors).length > 0 || !dirty}
                          isLoading={isLoading || isSubmitting}
                        >
                          Termin an Patienten senden
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Page>
  )
}

export default CreateAppointment
