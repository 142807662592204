import { AwsRum } from 'aws-rum-web'

export default function initRum() {
  try {
    const APPLICATION_ID = process.env.REACT_APP_CWR_APP_ID
    const APPLICATION_VERSION = '1.0.0'
    const APPLICATION_REGION = 'eu-central-1'

    if (!APPLICATION_ID) return

    const config = {
      sessionSampleRate: 0.5,
      endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
      identityPoolId: process.env.REACT_APP_CWR_IDENTITY_POOL_ID,
      telemetries: ['errors', 'performance', 'http'],
      allowCookies: true,
      enableXRay: false,
    }

    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    )

    window.rum = awsRum
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
