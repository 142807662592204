import { Flex, Link, Text } from '@chakra-ui/react'
import moment from 'moment'

const Footer = ({ target, backgroundColor = 'white' }) => (
  <Flex
    bg={backgroundColor}
    w="100%"
    justify="center"
    gap="4"
    px={{ base: '4', md: '8' }}
    py={{ base: '2', md: '4' }}
    fontSize={{ base: 'xs', md: 'sm' }}
  >
    <Text color="neutral.600">&copy; {moment().year()} TeleClinic GmbH</Text>
    <Flex color="primary.500">
      <Link target={target} href="/privacy">
        Datenschutzerklärung
      </Link>
      <Text color="neutral.600" px="2">
        |
      </Text>
      <Link target={target} href="/imprint">
        Impressum
      </Link>
    </Flex>
  </Flex>
)

export default Footer
